import React, { useState } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Texteditor = () => {
    const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  return (
    <div className="App">
      
      <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            
            />
    </div>
  );
};

export default Texteditor;
