import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import DashboardContext from "../../context/DashboardContext";
import { Outlet, Link } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const a = useContext(DashboardContext);
  const { islogin, logout } = a;

  function handleLogout(e) {
    e.preventDefault();
    // Clear authentication credentials

    localStorage.setItem("is_login", false);
    logout();
    navigate("/login");
  }
  return (
    <>
      <div className="page-main-header">
        <div className="main-header-right">
          {/* <div className="main-header-left d-lg-none">
                <div className="logo-wrapper"><Link to="index.html"><img className="blur-up lazyloaded" src="./assets/images/dashboard/multikart-logo.png" alt=""/></Link></div>
            </div> */}
          <div className="mobile-sidebar">
            <div className="media-body text-right switch-sm">
              <div className="logo-wrapper">
                <img
                  className="blur-up lazyloaded logo_size"
                  src="./assets/images/dashboard/marudhar_logo.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="nav-right col">
            <ul className="nav-menus">
              <li>
                <Link className="text-dark" to="/">
                  Home
                </Link>
              </li>
              {/*  <li className="onhover-dropdown"><Link className="txt-dark" to='/'>
                        <h6>Products</h6></Link>
                        <ul className="language-dropdown onhover-show-div p-20 profile-dropdown-hover">
                            <li><Link to="/" data-lng="en"><i className="flag-icon flag-icon-is"></i> English</Link></li>
                            <li><Link to="/" data-lng="es"><i className="flag-icon flag-icon-um"></i> Spanish</Link></li>
                            <li><Link to="/" data-lng="pt"><i className="flag-icon flag-icon-uy"></i> Portuguese</Link></li>
                            <li><Link to="/" data-lng="fr"><i className="flag-icon flag-icon-nz"></i> French</Link></li>
                        </ul>
                    </li>

                    <li className="onhover-dropdown"><Link className="txt-dark" to='/'>
                        <h6>Products</h6></Link>
                        <ul className="language-dropdown onhover-show-div p-20">
                            <li><Link to="/" data-lng="en"><i className="flag-icon flag-icon-is"></i> English</Link></li>
                            <li><Link to="/" data-lng="es"><i className="flag-icon flag-icon-um"></i> Spanish</Link></li>
                            <li><Link to="/" data-lng="pt"><i className="flag-icon flag-icon-uy"></i> Portuguese</Link></li>
                            <li><Link to="/" data-lng="fr"><i className="flag-icon flag-icon-nz"></i> French</Link></li>
                        </ul>
                    </li> */}

              <li className="onhover-dropdown">
                <Link className="txt-dark" to="/">
                  <h6>User Info</h6>
                </Link>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                  <li>
                    <Link to="/">Catalouge List</Link>
                  </li>
                  <li>
                    <Link to="/">Catalogue to user</Link>
                  </li>
                  <li>
                    <Link to="/consignor">Consignor Info</Link>
                  </li>
                  <li>
                    <Link to="/expertuser">Expert</Link>
                  </li>
                  <li>
                    <Link to="/addcdnumber">CD No</Link>
                  </li>
                  <li>
                    <Link to="/userinfo">User's Info</Link>
                  </li>
                  <li>
                    <Link to="/">User Membership Detail</Link>
                  </li>
                  <li>
                    <Link to="/">User Membership Plan(s)</Link>
                  </li>
                  <li>
                    <Link to="/">Society List</Link>
                  </li>
                  <li>
                    <Link to="/">Mass Phone</Link>
                  </li>
                  <li>
                    <Link to="/">Mass Email</Link>
                  </li>
                  {/* <li onClick={handleLogout}>Logout</li> */}
                </ul>
              </li>

              <li className="onhover-dropdown">
                <Link className="txt-dark" to="/">
                  <h6>Special Event</h6>
                </Link>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                  <li>
                    <Link to="/">NNE</Link>
                  </li>
                </ul>
              </li>

              <li className="onhover-dropdown">
                <Link className="txt-dark" to="/">
                  <h6>Quick Links</h6>
                </Link>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                  <li>
                    <Link to="/">Request For Limit Increment</Link>
                  </li>
                  <li>
                    <Link to="/">World City's</Link>
                  </li>
                </ul>
              </li>

              <li className="onhover-dropdown">
                <Link className="txt-dark" to="/">
                  <h6>Products</h6>
                </Link>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                  <li>
                    <Link to="/productcondition">Condition</Link>
                  </li>
                  <li>
                    <Link to="/">Consignor Lots</Link>
                  </li>
                  <li>
                    <Link to="/">Experts Reference</Link>
                  </li>
                  <li>
                    <Link to="/">Keywords/Tags</Link>
                  </li>
                  <li>
                    <Link to="/">Master Library</Link>
                  </li>
                  <li>
                    <Link to="/">
                      Move Multiple Products To Unlisted Products
                    </Link>
                  </li>
                  <li>
                    <Link to="/">Product Category</Link>
                  </li>
                  <li>
                    <Link to="/">Product Description Title</Link>
                  </li>
                  <li>
                    <Link to="/">Printed Reference</Link>
                  </li>
                  <li>
                    <Link to="/gstcategory">Product Tax Info</Link>
                  </li>
                  <li>
                    <Link to="/">R.T.C</Link>
                  </li>
                  <li>
                    <Link to="/showProducts">Unlisted Products</Link>
                  </li>
                  {/* addUnlistedproduct */}
                  {/* gstcategory */}
                </ul>
              </li>

              <li className="onhover-dropdown">
                <Link className="txt-dark" to="/">
                  <h6>Categories</h6>
                </Link>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                  <li>
                    <Link to="/categoryGroup">Group Category</Link>
                  </li>
                  <li>
                    <Link to="/cateogrymaster">Master Category</Link>
                  </li>
                  <li>
                    <Link to="/cateogrymain">Main Category</Link>
                  </li>
                  <li>
                    <Link to="/cateogrysub">Sub Category</Link>
                  </li>
                  <li>
                    <Link to="/cateogrysub2">Sub Category 2</Link>
                  </li>
                  <li>
                    <Link to="/cateogrysub3">Sub Category 3</Link>
                  </li>
                  <li>
                    <Link to="/cateogryhome">Home Page Category</Link>
                  </li>
                </ul>
              </li>

              <li className="onhover-dropdown">
                <Link className="txt-dark" to="/">
                  <h6>Pre Auction(s)</h6>
                </Link>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                  <li>
                    <Link to="/">Auction Setup</Link>
                  </li>
                  <li>
                    <Link to="/">Auction Bidding Slab Info</Link>
                  </li>
                  <li>
                    <Link to="/">Auction Errata</Link>
                  </li>
                  <li>
                    <Link to="/">
                      Import Code/End Time For Unlisted to Auction Live
                    </Link>
                  </li>
                  <li>
                    <Link to="/">Product Proposed For</Link>
                  </li>
                  <li>
                    <Link to="/">Products For Live Auction</Link>
                  </li>
                  <li>
                    <Link to="/">Word File From Unlisted Products</Link>
                  </li>
                </ul>
              </li>

              <li className="onhover-dropdown">
                <Link className="txt-dark" to="/">
                  <h6>Live Product</h6>
                </Link>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                  <li>
                    <Link to="/">Catalouge for live Auction</Link>
                  </li>
                  <li>
                    <Link to="/">E-Store</Link>
                  </li>
                  <li>
                    <Link to="/">E-Auction Live</Link>
                  </li>
                  <li>
                    <Link to="/">Printed Auction live</Link>
                  </li>
                  <li>
                    <Link to="/">Proxy Bids Setup</Link>
                  </li>
                  <li>
                    <Link to="/">Live Auction Quick Details</Link>
                  </li>
                </ul>
              </li>

              <li className="onhover-dropdown">
                <Link className="txt-dark" to="/">
                  <h6>Daily Watch</h6>
                </Link>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                  <li>
                    <Link to="/">Contact Query Head Info</Link>
                  </li>
                  <li>
                    <Link to="/">Contact Us Reply Template</Link>
                  </li>
                  <li>
                    <Link to="/">Decipher</Link>
                  </li>
                  <li>
                    <Link to="/">MA In Media</Link>
                  </li>
                  <li>
                    <Link to="/">News Letter Request</Link>
                  </li>
                  <li>
                    <Link to="/">Product Refer to friend</Link>
                  </li>
                </ul>
              </li>

              <li className="onhover-dropdown">
                <Link className="txt-dark" to="/">
                  <h6>Cart Info</h6>
                </Link>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                  <li>
                    <Link to="/">Auction Recept</Link>
                  </li>
                  <li>
                    <Link to="/">E-Shop</Link>
                  </li>
                  <li>
                    <Link to="/">E-Auction</Link>
                  </li>
                  <li>
                    <Link to="/">Printed Auction</Link>
                  </li>
                  <li>
                    <Link to="/">Payment Response</Link>
                  </li>
                  <li>
                    <Link to="/">Shipping Details</Link>
                  </li>
                  <li>
                    <Link to="/">User Pending Balance</Link>
                  </li>
                  <li>
                    <Link to="/">Pending Bidder Invoice</Link>
                  </li>
                  {/* <li onClick={handleLogout}>Logout</li> */}
                </ul>
              </li>

              <li className="onhover-dropdown">
                <h6>Auction Archive</h6>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                  <li>
                    <Link to="/">E-Auction</Link>
                  </li>
                  <li>
                    <Link to="/">Printed Auction</Link>
                  </li>
                  <li>
                    <Link to="/">Post Auction Quick Details</Link>
                  </li>
                </ul>
              </li>

              <li className="onhover-dropdown">
                <h6>Admin</h6>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                  <li>
                    <Link to="/">Audit Trail</Link>
                  </li>
                  <li>
                    <Link to="/">Block IP Address</Link>
                  </li>
                  <li>
                    <Link to="/">Messages Info </Link>
                  </li>
                  <li>
                    <Link to="/">Portal Setup</Link>
                  </li>
                  <li>
                    <Link to="/">Office Admin(Users info)</Link>
                  </li>
                  <li>
                    <Link to="/">Office Users Info</Link>
                  </li>
                  <li>
                    <Link to="/">Send Mail</Link>
                  </li>
                  <li>
                    <Link to="/">User Level Permission</Link>
                  </li>
                  <li>
                    <Link to="/">User's Login IP</Link>
                  </li>
                </ul>
              </li>

              {/* <li className="onhover-dropdown"><i data-feather="bell"></i><span className="badge badge-pill badge-primary pull-right notification-badge">3</span><span className="dot"></span>
                        <ul className="notification-dropdown onhover-show-div p-0">
                            <li>Notification <span className="badge badge-pill badge-primary pull-right">3</span></li>
                            <li>
                                <div className="media">
                                    <div className="media-body">
                                        <h6 className="mt-0"><span><i className="shopping-color" data-feather="shopping-bag"></i></span>Your order ready for Ship..!</h6>
                                        <p className="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="media">
                                    <div className="media-body">
                                        <h6 className="mt-0 txt-success"><span><i className="download-color font-success" data-feather="download"></i></span>Download Complete</h6>
                                        <p className="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="media">
                                    <div className="media-body">
                                        <h6 className="mt-0 txt-danger"><span><i className="alert-color font-danger" data-feather="alert-circle"></i></span>250 MB trash files</h6>
                                        <p className="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                    </div>
                                </div>
                            </li>
                            <li className="txt-dark"><Link to="/">All</Link> notification</li>
                        </ul>
                    </li> */}
              {/*  <li><Link to="/"><i className="right_side_toggle" data-feather="message-square"></i><span className="dot"></span></Link></li> */}
              <li className="onhover-dropdown">
                <div className="media align-items-center">
                  <img
                    className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
                    src="./assets/images/dashboard/marudharOwner.jpeg"
                    alt="header-user"
                  />
                  <div className="dotted-animation">
                    <span className="animate-circle"></span>
                    <span className="main-circle"></span>
                  </div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                  <li>
                    <Link to="/">Edit Profile</Link>
                  </li>
                  <li>
                    <Link to="/">Inbox</Link>
                  </li>
                  <li>
                    <Link to="/">Lock Screen</Link>
                  </li>
                  <li>
                    <Link to="/">Settings</Link>
                  </li>
                  <li onClick={handleLogout}>Logout</li>
                </ul>
              </li>
            </ul>
            <div className="d-lg-none mobile-toggle pull-right"></div>
          </div>
        </div>
      </div>
    </>
  );
}
