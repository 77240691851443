/* CategoryMaster */

import React,{useContext,useRef,useEffect,useState,useCallback} from 'react'
import Allusers from '../Allusers';
import DashboardContext from '../../context/DashboardContext';
//import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { EditorState , convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Texteditor from './Texteditor';
import { MultiSelect } from "react-multi-select-component";
import AddFields from '../productdescription/AddFields';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';

//import Accordion from 'react-bootstrap/Accordion';


export default function AddUnlistedProducts() {

  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  
  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles.map(file => ({
      name: file.name,
      preview: URL.createObjectURL(file)
    })));
    console.log("dropzonefile-------------->",acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const [files, setFiles] = useState([]);

  const [selected, setSelected] = useState([]);
  const [selectedExprt, setSelectedExprt] = useState([]);
  const [selectedKwd, setSelectedKwd] = useState([]);

  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);
  const [lgShow3, setLgShow3] = useState(false);
  const [lgShow4, setLgShow4] = useState(false);
  const [lgShow5, setLgShow5] = useState(false);
  const [lgcityShow, setLgcityShow] = useState(false);
  const [lghomecatShow, setLghomecatShow] = useState(false);
  const [condShow, setLgCondShow] = useState(false);
  const [exprtShow, setLgexprtShow] = useState(false);
  const [referShow, setLgreferShow] = useState(false);
  const [keywordShow, setLgkeywordShow] = useState(false);

  const [groupselectedVal, setgroupselectedVal] = useState();
  const [groupselectedKey, setgroupselectedKey] = useState();

  const [masterselectedVal, setmasterselectedVal] = useState();
  const [masterselectedKey, setmasterselectedKey] = useState();

  const [mainerselectedVal, setmainselectedVal] = useState();
  const [mainselectedKey, setmainselectedKey] = useState();

  const [subselectedVal, setsubselectedVal] = useState();
  const [subselectedKey, setsubselectedKey] = useState();

  const [suboneerselectedVal, setsuboneselectedVal] = useState();
  const [suboneselectedKey, setsuboneselectedKey] = useState();

  const [subtwoerselectedVal, setsubtwoselectedVal] = useState();
  const [subtwoselectedKey, setsubtwoselectedKey] = useState();
  const [group_catname, setGroupcategory] = useState();
  const [headNdesc, setHeadNdesc] = useState();


  const [cdsrn, setcdsrn] = useState();
  




  const a = useContext(DashboardContext);
  const modalRef = useRef(null);
  const formRef = useRef(null);
  const formRefmain = useRef(null);
  const formRefsub = useRef(null);
  const formRefsub1 = useRef(null);
  const formRefsub2 = useRef(null);

  const {getCategoryGroup,categoryGroup,addCategoryMaster,categoryMasteradd,getcategoryMasterdropdown,mastercategory,fetchAllgst,gstAll,addCategoryMain,getcategoryMaindropdown,maincategory,addCategorySub,subcategories,subcategoriestwo,subcategoriesthree,fetchSubcategories,productPageData,addcity,getaddproductData,addHomeCategorgy,homeCatdata,cdnumbers,addCondition,conditions,usersData,expertusers,create_expertUser,add_references,referenceData,add_keyword,keywordsData,add_product,productResponse} = a; 


  console.log("referencedata-------------->",referenceData);
  const referenceoptions = (referenceData.reference_dropdown != undefined)?referenceData.reference_dropdown:[];

  const keywordsDataoptions = (keywordsData.keywods_dropdown != undefined )?keywordsData.keywods_dropdown:[];


  /* const expertoptions = (expertusers.length > 0)?expertusers.exprt_dropdown:[]; */

  /* const [expertOptions, setExpertoption] = useState(); */
  /* console.log("adasd------------------>",expertusers.exprt_dropdown); */
  /* const [first, setfirst] = useState(categoryGroup) */
  const handleSubmitCategory = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let parent_id = data.get('parent_id');
    let category_master = data.get('category_master');
    let level = data.get('level');
    console.log("categoryMaster add",categoryMasteradd);
    addCategoryMaster(category_master,parent_id,level);
    console.log("categoryMaster add2",categoryMasteradd);
        formRef.current.reset();
        setLgShow(false);
    }

  /* if ( categoryMasteradd.Success) {
        
    } */
  console.log("categoryGroup",categoryGroup);
  useEffect(() => {
    console.log("user",localStorage.getItem('is_login'));
    getCategoryGroup();
    // eslint-disable-next-line
  }, [])
  
  const handleParentcategoryChange = (e) =>{
   /*  alert(e.target.selectedIndex); */

    setGroupcategory(categoryGroup[e.target.selectedIndex-1].category);
    setgroupselectedVal(categoryGroup[e.target.selectedIndex-1].category);
    setgroupselectedKey(e.target.value);
    getcategoryMasterdropdown(e.target.value);


  }

  const handleSubmitCategorymain = (e)=>{

    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let mainparent_id = data.get('parent_id');
    let category_main = data.get('category_main');
    
    let level = data.get('level');
    let gst_id = data.get('gst_id');
    let meta_desc = data.get('meta_desc');
    
    addCategoryMain(mainparent_id,category_main,level,gst_id,meta_desc);
        
        formRefmain.current.reset();
        setLgShow2(false);

  }
  

  const handleSubmitCategorysub = (e)=>{

    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let parent_id = data.get('parent_id');
    let category = data.get('category');
    let level = data.get('level');
    
    addCategorySub(parent_id,category,level);
        
        formRefsub.current.reset();
        setLgShow3(false);

  }

  const handleSubmitCategorysubone = (e)=>{

    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let parent_id = data.get('parent_id');
    let category = data.get('category');
    let level = data.get('level');
    
    addCategorySub(parent_id,category,level);
        
        formRefsub1.current.reset();
        setLgShow4(false);

  }

  const handleSubmitCategorysubtwo = (e)=>{

    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let parent_id = data.get('parent_id');
    let category = data.get('category');
    let level = data.get('level');
    
    addCategorySub(parent_id,category,level);
        
        formRefsub2.current.reset();
        setLgShow5(false);

  }

  const handleChangeMaster = (e) =>{
    fetchAllgst();
    setmasterselectedVal(mastercategory[e.target.selectedIndex-1].category);
    setmasterselectedKey(e.target.value);
    getcategoryMaindropdown(e.target.value);
  }
  const handlemaincategoryChange = (e) =>{
    setmainselectedVal(maincategory[e.target.selectedIndex-1].category_main);
    setmainselectedKey(e.target.value);
    fetchSubcategories(e.target.value,'sub1');
  }
  const handleSubcategoryChange = (e) => {
    setsubselectedVal(subcategories[e.target.selectedIndex-1].category);
    setsubselectedKey(e.target.value);
    fetchSubcategories(e.target.value,'sub2');
  }
  const handleSubcategory2Change = (e) => {
    setsuboneselectedVal(subcategoriestwo[e.target.selectedIndex-1].category);
    setsuboneselectedKey(e.target.value);
    fetchSubcategories(e.target.value,'sub3');
  }
  const handleSubcategory3Change = (e) => {
    setsubtwoselectedVal(subcategoriesthree[e.target.selectedIndex-1].category);
    setsubtwoselectedKey(e.target.value,'sub4');
   
  }

  const handlesubmitCity =(e)=>{

    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let cityi = data.get('city');
    
    addcity(cityi);
        
      /* formRefsub1.current.reset(); */
      setLgcityShow(false);

  }

  const handlehomeCat = (e) =>{

    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let group = data.get('group');
    let master = data.get('master');
    let main = data.get('main');
    let sub = data.get('sub');
    let sub2 = data.get('sub2');
    let sub3 = data.get('sub3');
    let home_cat = data.get('home_cat');
    let keyword = data.get('keyword');
    
    addHomeCategorgy(group,master,main,sub,sub2,sub3,home_cat,keyword);
        
      /* formRefsub1.current.reset(); */
      setLghomecatShow(false)
  }

  const handleCdchange = (e) =>{
    const cdn = e.target.value;
    const lastFourDigits = cdn.slice(-4);
    setcdsrn(lastFourDigits);
  }


  const handlesubmitCondition = (e) =>{
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let cond = data.get('cond');
    let status = data.get('status');

    addCondition(cond,status);

     modalRef.current.reset();
     setLgCondShow(false);

  }

  const handlesubmitReference = (e) =>{
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    if(!groupselectedKey)
    {
      alert("please select group category first");
    }else{

      let groupcatID = groupselectedKey;
      let references = data.get('references');
      let status = data.get('status');
      add_references(groupcatID,references,status);
      modalRef.current.reset();
      setLgreferShow(false);

    }
  }
  const handlesubmitExpert = (e) =>{
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let userID = data.get('userID');
    let is_expert_user = data.get('is_expert_user');
    modalRef.current.reset();
    exprtShow(false);

    create_expertUser(userID,is_expert_user);
  }

  const handlesubmitkeywords = (e) =>{
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let keyword = data.get('keyword');
    add_keyword(keyword);
    modalRef.current.reset();
    setLgkeywordShow(false)
    
  }

  const handleAddProductSubmit = (e)=>{
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    
    let auction_type  = data.get('auction_type');
    let home_cat      = data.get('home_cat');
    let auction_city  = data.get('auction_city');
    let cdsrno        = data.get('cdsrno');
    let qnty          = data.get('qnty');
    let size          = data.get('size');
    let whgt          = data.get('whgt');
    let cond          = data.get('cond');
    let start_bid_price = data.get('start_bid_price');
    let estimate_price  = data.get('estimate_price');
    let reserv_price  = data.get('reserv_price');
    let e_shopprice   = data.get('e_shopprice');
    let discount_price  = data.get('discount_price');
    /* let selectedExprt   = data.get('selectedExprt'); */
    /* let selected    = data.get('selected'); */
    let shplcl  = data.get('shplcl');
    let shpabrd = data.get('shpabrd');
    let shpmtd  = data.get('shpmtd');
    let title = data.get("title");
    console.log("expert---->",shpmtd);

    add_product(title,groupselectedKey,masterselectedKey,mainselectedKey,subselectedKey,suboneselectedKey,subtwoselectedKey,auction_city,auction_type,home_cat,cdsrn,cdsrno,qnty,size,whgt,cond,start_bid_price,estimate_price,reserv_price,e_shopprice,discount_price,selectedExprt,selected,shplcl,shpabrd,shpmtd,selectedKwd,headNdesc);

    /* const rawContentState = convertToRaw(editorState.getCurrentContent());
    const plainText = rawContentState.blocks.map((block) => block.text).join('\n'); */
    console.log("plain text",editorState.getCurrentContent().length);
  }

  const handleChildValue = (value) =>{
    setHeadNdesc(value);
  }

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
  };


  if (productResponse.Success) {
    navigate('/showProducts');
  }

  const handleCdSrno = (e) =>{
    setcdsrn(e.target.value);
  }


  
  /* const {cities} = productPageData;
  console.log("cities----->data",productPageData); */
  

  return (
    <>
      <div className="page-body">
        <div className="container-fluid">
            <div className="page-header">
              
                <div className="row">
                    <div className="col-lg-6">
                        <div className="page-header-left">
                            <h3>Add Products
                                <small>MarudharArts Admin panel</small>
                            </h3>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <ol className="breadcrumb pull-right">
                            <li className="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li>
                            <li className="breadcrumb-iteem">Dashboard</li>
                            <li className="breadcrumb-itm active">Categories</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                    {/* <h5>Manage Products</h5> */}
                    <div className="row">
                      <div className="col-sm-6">
                        <h5>Manage Product</h5>
                      </div>
                      
                    </div>
                </div>
                <div className="card-body">
                {/* dropdown tree */}
                <div className="row">
                    <div className="col-sm-6">
                        <div className="row">
                            <dv className="col">
                                <label for="recipient-name" className="col-form-label"><strong>Group Category</strong><span className="required"></span></label>
                                </dv>
                            <div className="col">
                                {(Object.keys(categoryGroup).length > 0) && <select className='form-control' onChange={handleParentcategoryChange}>
                                    <option value='' selected disabled> Please Select</option>
                                        
                                        {categoryGroup.map((group, index) => (
                                            <option key={index} value={group._id}>
                                                {group.category}
                                            </option>
                                        ))}

                                </select>}

                            </div>
                            <div className="col">
                                {/* <button className='btn btn-sm btn-primary'>+</button>
                                &nbsp;<button className='btn btn-sm btn-primary'>E</button>
                                &nbsp;<button className='btn btn-sm btn-primary'>D</button> */}
                            </div>
                        </div>

                        {/* <div className="row mt-4">
                            <dv className="col">
                                <label for="recipient-name" className="col-form-label">Category Group <span className="required"></span></label>
                                </dv>
                            <div className="col">
                                <select className='form-control'>
                                    <option value='' selected disabled> Please Select</option>
                                </select>

                            </div>
                            <div className="col">
                                <button className='btn btn-sm btn-primary'>+</button>
                                &nbsp;<button className='btn btn-sm btn-primary'>E</button>
                                &nbsp;<button className='btn btn-sm btn-primary'>D</button>
                            </div>
                        </div> */}


                        <div className="row mt-4">
                            <dv className="col">
                                <label for="recipient-name" className="col-form-label"><strong>Master Category</strong><span className="required"></span></label>
                                </dv>
                            <div className="col">
                                <select className='form-control' onChange={handleChangeMaster}>
                                    <option value='' selected disabled> Please Select</option>
                                    {console.log("mastercategory", mastercategory)}
                                        {mastercategory.map((master, index) => (
                                            <option key={index} value={master._id}>
                                                {master.category}
                                            </option>
                                        ))}
                                </select>

                            </div>
                            <div className="col">
                                <button className='btn btn-sm btn-primary' onClick={() => setLgShow(true)}>+</button>
                                &nbsp;<button className='btn btn-sm btn-primary'>E</button>
                                &nbsp;<button className='btn btn-sm btn-primary'>D</button>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <dv className="col">
                                <label for="recipient-name" className="col-form-label"><strong>Main Category</strong> <span className="required"></span></label>
                            </dv>
                            <div className="col">
                                <select className='form-control' onChange={handlemaincategoryChange}>
                                <option value='' selected disabled> Please Select</option>       
                                    {maincategory.map((main, index) => (
                                            <option key={index} value={main._id}>
                                                {main.category_main}
                                            </option>
                                        ))}
                                    
                                </select>

                            </div>
                            <div className="col">
                                <button className='btn btn-sm btn-primary'  onClick={() => setLgShow2(true)}>+</button>
                                &nbsp;<button className='btn btn-sm btn-primary'>E</button>
                                &nbsp;<button className='btn btn-sm btn-primary'>D</button>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <dv className="col">
                                <label for="recipient-name" className="col-form-label"><strong>Sub Category</strong> <span className="required"></span></label>
                                </dv>
                            <div className="col">
                                <select className='form-control' onChange={handleSubcategoryChange}>
                                    <option value='' selected disabled> Please Select</option>
                                    {subcategories.map((sub, index) => (
                                      <option key={index} value={sub._id}>
                                        {sub.category}
                                      </option>
                                    ))}
                                </select>

                            </div>
                            <div className="col">
                                <button className='btn btn-sm btn-primary'  onClick={() => setLgShow3(true)}>+</button>
                                &nbsp;<button className='btn btn-sm btn-primary'>E</button>
                                &nbsp;<button className='btn btn-sm btn-primary'>D</button>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <dv className="col">
                                <label for="recipient-name" className="col-form-label"><strong>Sub Category 2</strong><span className="required"></span></label>
                                </dv>
                            <div className="col">
                                <select className='form-control' onChange={handleSubcategory2Change}>
                                    <option value='' selected disabled> Please Select</option>
                                    {subcategoriestwo.map((sub, index) => (
                                      <option key={index} value={sub._id}>
                                        {sub.category}
                                      </option>
                                    ))}
                                </select>

                            </div>
                            <div className="col">
                                <button className='btn btn-sm btn-primary'  onClick={() => setLgShow4(true)}>+</button>
                                &nbsp;<button className='btn btn-sm btn-primary'>E</button>
                                &nbsp;<button className='btn btn-sm btn-primary'>D</button>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <dv className="col">
                                <label for="recipient-name" className="col-form-label"><strong>Sub Category 2</strong> <span className="required"></span></label>
                                </dv>
                            <div className="col">
                                <select className='form-control' onChange={handleSubcategory3Change}>
                                    <option value='' selected disabled> Please Select</option>
                                    {subcategoriesthree.map((sub, index) => (
                                      <option key={index} value={sub._id}>
                                        {sub.category}
                                      </option>
                                    ))}
                                </select>

                            </div>
                            <div className="col">
                                <button className='btn btn-sm btn-primary'  onClick={() => setLgShow5(true)}>+</button>
                                &nbsp;<button className='btn btn-sm btn-primary'>E</button>
                                &nbsp;<button className='btn btn-sm btn-primary'>D</button>
                            </div>
                        </div>

                    </div>
                </div>
                
                
                {/* End */}
                </div>
            </div>
          </div>
        </div>
        {/* city modal */}
        <Modal
        size="lg"
        show={lgcityShow}
        onHide={() => setLgcityShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Auction City
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handlesubmitCity}>
        <Modal.Body>
        <div className="form-group">
          <div className="row">
            <div className="col">
              <label for="validationCustom0"><span>*</span><strong>City Name</strong></label>
              </div>
              <div className="col">
              <input className="form-control" id="validationCustom0" type="text" required="" name="city"/>
              </div>
          </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" className="btn btn-primary">Save</button>
        </Modal.Footer>
        </form>
      </Modal>
        {/* End */}


        {/* Home page category modal */}
        <Modal
        size="lg"
        show={lghomecatShow}
        onHide={() => setLghomecatShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Home Page Category
          </Modal.Title>  
        </Modal.Header> 
        <form onSubmit={handlehomeCat}>
        <Modal.Body>
          <div className="row">
              <div className="col-sm-12">
                  <div className="row">
                      <dv className="col">
                          <label for="recipient-name" className="col-form-label"><strong>Group Category</strong><span className="required"></span></label>
                          </dv>
                      <div className="col">
                          {(Object.keys(categoryGroup).length > 0) && <select className='form-control' name='group' onChange={handleParentcategoryChange}>
                              <option value='' selected disabled> Please Select</option>
                                  
                                  {categoryGroup.map((group, index) => (
                                      <option key={index} value={group._id}>
                                          {group.category}
                                      </option>
                                  ))}

                          </select>}

                      </div>
                      <div className="col">
                          {/* <button className='btn btn-sm btn-primary'>+</button>
                          &nbsp;<button className='btn btn-sm btn-primary'>E</button>
                          &nbsp;<button className='btn btn-sm btn-primary'>D</button> */}
                      </div>
                  </div>
                  <div className="row mt-4">
                      <dv className="col">
                          <label for="recipient-name" className="col-form-label"><strong>Master Category</strong><span className="required"></span></label>
                          </dv>
                      <div className="col">
                          <select className='form-control' name='master' onChange={handleChangeMaster}>
                              <option value='' selected disabled> Please Select</option>
                              {console.log("mastercategory", mastercategory)}
                                  {mastercategory.map((master, index) => (
                                      <option key={index} value={master._id}>
                                          {master.category}
                                      </option>
                                  ))}
                          </select>

                      </div>
                      <div className="col">
                          
                      </div>
                  </div>

                  <div className="row mt-4">
                      <dv className="col">
                          <label for="recipient-name" className="col-form-label"><strong>Main Category</strong> <span className="required"></span></label>
                      </dv>
                      <div className="col">
                          <select className='form-control' name='main' onChange={handlemaincategoryChange}>
                          <option value='' selected disabled> Please Select</option>       
                              {maincategory.map((main, index) => (
                                      <option key={index} value={main._id}>
                                          {main.category_main}
                                      </option>
                                  ))}
                              
                          </select>

                      </div>
                      <div className="col">
                          
                      </div>
                  </div>

                  <div className="row mt-4">
                      <dv className="col">
                          <label for="recipient-name" className="col-form-label"><strong>Sub Category</strong> <span className="required"></span></label>
                          </dv>
                      <div className="col">
                          <select className='form-control' name='sub' onChange={handleSubcategoryChange}>
                              <option value='' selected disabled> Please Select</option>
                              {subcategories.map((sub, index) => (
                                <option key={index} value={sub._id}>
                                  {sub.category}
                                </option>
                              ))}
                          </select>

                      </div>
                      <div className="col">
                          
                      </div>
                  </div>

                  <div className="row mt-4">
                      <dv className="col">
                          <label for="recipient-name" className="col-form-label"><strong>Sub Category 2</strong><span className="required"></span></label>
                          </dv>
                      <div className="col">
                          <select className='form-control' name='sub2' onChange={handleSubcategory2Change}>
                              <option value='' selected disabled> Please Select</option>
                              {subcategoriestwo.map((sub, index) => (
                                <option key={index} value={sub._id}>
                                  {sub.category}
                                </option>
                              ))}
                          </select>

                      </div>
                      <div className="col">
                          
                      </div>
                  </div>

                  <div className="row mt-4">
                      <dv className="col">
                          <label for="recipient-name" className="col-form-label"><strong>Sub Category 2</strong> <span className="required"></span></label>
                          </dv>
                      <div className="col">
                          <select className='form-control' name='sub3' onChange={handleSubcategory3Change}>
                              <option value='' selected disabled> Please Select</option>
                              {subcategoriesthree.map((sub, index) => (
                                <option key={index} value={sub._id}>
                                  {sub.category}
                                </option>
                              ))}
                          </select>

                      </div>
                      <div className="col">
                          
                      </div>
                  </div>

                  <div className="row mt-4">
                      <dv className="col">
                          <label for="recipient-name" className="col-form-label"><strong>Home Page Category</strong> <span className="required"></span></label>
                          </dv>
                      <div className="col">
                        <input className="form-control" id="validationCustomtitle" type="text" required="" name="home_cat" placeholder='Home category'/>
                      </div>
                      <div className="col">
                        
                      </div>
                  </div>

                  <div className="row mt-4">
                      <dv className="col">
                          <label for="recipient-name" className="col-form-label"><strong>Keyword</strong> <span className="required"></span></label>
                          </dv>
                      <div className="col">
                        <input className="form-control" id="validationCustomtitle" type="text" required="" name="keyword" placeholder='Keyword'/>
                      </div>
                      <div className="col">
                        
                      </div>
                  </div>

              </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" className="btn btn-primary">Save</button>
        </Modal.Footer>
        </form>
      </Modal>
        {/* End */}



        {/* Condition modal */}
        <Modal
        size="lg"
        show={condShow}
        onHide={() => setLgCondShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Condition
          </Modal.Title>
        </Modal.Header>
        <form ref={modalRef} onSubmit={handlesubmitCondition}>
        <Modal.Body>
        <div className="form-group">
          <div className="row">
              <div className="col">
                <label for="validationCustom0"><span>*</span><strong>Condition</strong></label>
              </div>
              <div className="col">
                <input className="form-control" id="validationCustom0" type="text" required="" name="cond" placeholder='Codition'/>
              </div>
          </div>
          <br/>
          <div className="row">
              <div className="col">
                <label for="validationCustom0"><span>*</span><strong>Status</strong></label>
              </div>
              
              <div className="col">
               <select className='form-control' name='status'>
                    <option value="">---Please Select----</option>
                   
                    <option value="1">Active</option>
                    <option value="0">Freez</option>
               </select>
              </div>
          </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" className="btn btn-primary">Save</button>
        </Modal.Footer>
        </form>
      </Modal>
        {/* End */}

      {/* Expert Modal */}
        <Modal
        size="lg"
        show={exprtShow}
        onHide={() => setLgexprtShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Expert
          </Modal.Title>
        </Modal.Header>
        <form ref={modalRef} onSubmit={handlesubmitExpert}>
        <Modal.Body>
        <div className="form-group">
          <div className="row">
              <div className="col">
                <label for="validationCustom0"><span>*</span><strong>User</strong></label>
              </div>
              <div className="col">
                <select className='form-control' name='userID'>
                  <option value="">--Select User--</option>
                  {(Object.keys(usersData).length > 0) && usersData.map((user, index) => (
                    <option key={index} value={user._id}>
                     {user.username}
                    </option>
                  ))}
                </select>
              </div>
          </div>
          <br/>
          <div className="row">
              <div className="col">
                <label for="validationCustom0"><span>*</span><strong>Expert</strong></label>
              </div>
              
              <div className="col">
               <select className='form-control' name='is_expert_user'>
                    <option value="">---Please Select----</option>
                   
                    <option value="1">Yes</option>
                    <option value="0">No</option>
               </select>
              </div>
          </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" className="btn btn-primary">Save</button>
        </Modal.Footer>
        </form>
      </Modal>
      {/* End */}


      {/* KEW WORD Modal */}
      <Modal
        size="lg"
        show={keywordShow}
        onHide={() => setLgkeywordShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Keyword
          </Modal.Title>
        </Modal.Header>
        <form ref={modalRef} onSubmit={handlesubmitkeywords}>
        <Modal.Body>
        <div className="form-group">
          <div className="row">
              <div className="col">
                <label for="validationCustom0"><span>*</span><strong>Keyword</strong></label>
              </div>
              <div className="col">
                <input type="text" className='form-control' name='keyword' placeholder="Enter Keyword"/>
              </div>
          </div>
          <br/>
          
        </div>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" className="btn btn-primary">Save</button>
        </Modal.Footer>
        </form>
      </Modal>
      {/* End */}

       {/* References Modal */}
       <Modal
        size="lg"
        show={referShow}
        onHide={() => setLgreferShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Reference
          </Modal.Title>
        </Modal.Header>
        <form ref={modalRef} onSubmit={handlesubmitReference}>
        <Modal.Body>
        <div className="form-group">
          <div className="row">
              <div className="col">
                <label for="validationCustom0"><span>*</span><strong>Group Category</strong></label>
              </div>
              <div className="col">
                <input type="text" className='form-control' readOnly value={group_catname} name="groupcatID"/>
              </div>
          </div>
          <br/>
          <div className="row">
              <div className="col">
                <label for="validationCustom0"><span>*</span><strong>Reference Book Name</strong></label>
              </div>
              
              <div className="col">
               <textarea className='form-control' placeholder='Reference Book Name' name ="references">
                    
               </textarea>
              </div>

              
          </div>
          <br/>
          <div className="row">
              <div className="col">
                <label for="validationCustom0"><span>*</span><strong>Status</strong></label>
              </div>
              <div className="col">
               <select className='form-control' name='status'>
                    <option value="1" selected>Active</option>
                    <option value="0">Freez</option>
               </select>
              </div>
          </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" className="btn btn-primary">Save</button>
        </Modal.Footer>
        </form>
      </Modal>
      {/* End */}

        <form onSubmit={handleAddProductSubmit} id="product_form">
        <div className="row product-adding">
            <div className="col-xl-6">
                <div className="card">
                    <div className="card-header">
                        <h5>General</h5>
                    </div>
                    
                    <div className="card-body">
                        <div className="digital-add needs-validation">
                            <div className="form-group">
                                <label for="validationCustom01" className="col-form-label pt-0"><span>*</span> Title</label>
                                <input className="form-control" id="validationCustom01" type="text" required="" name='title'/>
                            </div>
                            
                            <br/>
                            <div className="form-group">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                      CD Number
                                    </button>
                                  </h2>
                                  <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                      <div class="tab-pane active show" id="account" role="tabpanel" aria-labelledby="account-tab">
                                      {/* <form className="needs-validation user-add" novalidate=""> */}
                                            <div className="form-group">
                                              <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span>*</span> Auction City</label>
                                                </div>
                      
                                                <div className="col m-checkbox-inline">
                                                  <select className='form-control' name='auction_city'>
                                                    <option value=''>---Please Select---</option>
                                                    {(Object.keys(productPageData).length > 0) && productPageData.map((city, index) => (
                                                      <option key={index} value={city._id}>
                                                        {city.city}
                                                      </option>
                                                    ))}
                                                  </select>
                                                  
                                                </div>
                                                <div className='col inline'><a className='btn btn-sm btn-primary' onClick={() => setLgcityShow(true)}>+</a></div>
                                              </div>
                                            </div>

                                            <div className="form-group mt-2">
                                            <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span>*</span> Auction Type</label>
                                                </div>
                                                <div className="col m-checkbox-inline">
                                                  <select className='form-control' name='auction_type'>
                                                    <option value=''>---Please Select---</option>
                                                    <option value='1'>e-Auction</option>
                                                    <option value='2'>p-Auction</option>
                                                    <option value='3'>e-Shop</option>
                                                  </select>
                                                  
                                                </div>
                                                <div className='col inline'>{/* <a className='btn btn-sm btn-primary'>+</a> */}</div>
                                              
                                              </div>
                                            </div>
                                            <div className="form-group mt-2">
                                            <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span>*</span> Category Home Page</label>
                                                </div>
                                                <div className="col m-checkbox-inline">
                                                  <select className='form-control' name='home_cat'>
                                                    <option value=''>---Please Select---</option>
                                                    {(Object.keys(homeCatdata).length > 0) && homeCatdata.map((homecat, index) => (
                                                      <option key={index} value={homecat._id}>
                                                        {homecat.category}
                                                      </option>
                                                    ))}
                                                  </select>
                                                  
                                                </div>
                                                <div className='col inline'><a className='btn btn-sm btn-primary' onClick={() => setLghomecatShow(true)}>+</a></div>
                                              </div>
                                            </div>
                                            <div className="form-group mt-2">
                                            <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span>*</span> CD No</label>
                                                </div>
                                                <div className="col m-checkbox-inline">
                                                  <select className='form-control' onChange={handleCdchange}>
                                                    <option value=''>---Please Select---</option>
                                                    {(Object.keys(cdnumbers).length > 0) && cdnumbers.map((cd, index) => (
                                                      <option key={index} value={cd.cdno}>
                                                        {cd.cdno},{cd.username}
                                                      </option>
                                                    ))}
                                                  </select>
                                                  
                                                </div>
                                                <div className='col inline'></div>
                                              </div>
                                            </div>
                                            <div className="form-group mt-2">
                                            <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span>*</span> CD Sr No</label>
                                                </div>
                                               
                                                <div className="col m-checkbox-inline">
                                                <input className="form-control" id="validationCustom0" type="text" required="" value={cdsrn} name='cdsrno' onChange={handleCdSrno}/>
                                                  
                                                </div>
                                                <div className='col inline'></div>
                                              </div>
                                            </div>
                                        {/* </form> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <br/>
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                      Details
                                    </button>
                                  </h2>
                                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    <div class="tab-pane active show" id="account" role="tabpanel" aria-labelledby="account-tab">
                                      {/* <form className="needs-validation user-add" novalidate=""> */}
                                            
                                            <div className="form-group">
                                              <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span>*</span> Quantity</label>
                                                </div>
                                                <div className="col">
                                                <input className="form-control" id="validationCustom0" type="text" required="" name='qnty'/>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="form-group mt-2">
                                            <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span>*</span> Size(Height in MM)</label>
                                                </div>
                                                <div className="col">
                                                <input className="form-control" id="validationCustom0" type="text" required="" name='size'/>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="form-group mt-2">
                                            <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span>*</span> Weight</label>
                                                </div>
                                                <div className="col">
                                                <input className="form-control" id="validationCustom0" type="text" required="" name='whgt'/>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="form-group mt-2">
                                            <div className="row">
                                                {/* <div className="col">
                                                <label for="validationCustom0"><span>*</span>Condition</label>
                                                </div>
                                                <div className="col">
                                                <select className='form-control'>
                                                  <oprtion value="">---Select One---</oprtion>
                                                  

                                                </select>
                                                </div> */}
                                                
                                                <div className="col con-margin">
                                                <label for="validationCustom0"><span>*</span> Condition</label>
                                                </div>
                                                <div className="col m-checkbox-inline">
                                                  <select className='form-control con-width-select' name='cond'>
                                                    {/* {console.log("copndition----------->")} */}
                                                    <option value=''>---Please Select---</option>
                                                    {(Object.keys(conditions).length > 0) && conditions.map((con, index) => (
                                                      <option key={index} value={con._id}>
                                                        {con.condtion}
                                                      </option>
                                                    ))}
                                                  </select>
                                                  
                                                </div>
                                                <div className='col inline con-margin-add'><a className='btn btn-sm btn-primary' onClick={() => setLgCondShow(true)}>+</a></div>
                                                
                                              </div>

                                              
                                            </div>
                                            
                                       {/*  </form> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <br/> 
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                      Price
                                    </button>
                                  </h2>
                                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    <div class="tab-pane active show" id="account" role="tabpanel" aria-labelledby="account-tab">
                                      {/* <form className="needs-validation user-add" novalidate=""> */}
                                            
                                            <div className="form-group">
                                              <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span>*</span> Start Bid Price</label>
                                                </div>
                                                <div className="col">
                                                <input className="form-control" id="validationCustom0" type="text" required="" name="start_bid_price"/>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="form-group mt-2">
                                            <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span>*</span> Estimated Price</label>
                                                </div>
                                                <div className="col">
                                                <input className="form-control" id="validationCustom0" type="text" required="" name="estimate_price"/>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="form-group mt-2">
                                            <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span>*</span> Reserve Price</label>
                                                </div>
                                                <div className="col">
                                                <input className="form-control" id="validationCustom0" type="text" required="" name="reserv_price"/>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="form-group mt-2">
                                            <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span></span>e Shop Price </label>
                                                </div>
                                                <div className="col">
                                                <input className="form-control" id="validationCustom0" type="text" required="" name="e_shopprice"/>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="form-group mt-2">
                                            <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span>*</span> e Shop Discounted Price</label>
                                                </div>
                                                <div className="col">
                                                <input className="form-control" id="validationCustom0" type="text" required="" name="discount_price"/>
                                                </div>
                                              </div>
                                            </div>
                                        {/* </form> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <br/>
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFour">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                      References
                                    </button>
                                  </h2>
                                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    <div class="tab-pane active show" id="account" role="tabpanel" aria-labelledby="account-tab">
                                      {/* <form className="needs-validation user-add" novalidate=""> */}

                                            <div className="form-group mt-2">
                                            <div className="row">
                                            
                                              <div className="col">
                                                    <label htmlFor="reference" className="col-form-label">
                                                      Expert <span className="required">*</span>
                                                    </label>
                                              </div>
                                              <div className="col">
                                               {console.log("expertoptions----------------->",expertusers)}
                                                  <MultiSelect
                                                      options={(expertusers.exprt_dropdown != undefined)?expertusers.exprt_dropdown:[]}
                                                      value={selectedExprt}
                                                      onChange={setSelectedExprt}
                                                      labelledBy="Select"
                                                      id="reference"
                                                    />
                                              </div>
                                              <div className="col"><a className='btn btn-sm btn-primary' onClick={() => setLgexprtShow(true)}>+</a></div>
                                                    
                                                </div>
                                            </div>
                                           
                                            <div className="form-group mt-2">
                                            <div className="row">
                                                  <div className="col">
                                                    <label htmlFor="reference" className="col-form-label">
                                                      Reference <span className="required">*</span>
                                                    </label>
                                                  </div>
                                                  <div className="col">
                                                    <MultiSelect
                                                      options={referenceoptions}
                                                      value={selected}
                                                      onChange={setSelected}
                                                      labelledBy="Select"
                                                      id="reference"
                                                    />
                                                  </div>
                                                  <div className="col"><a className='btn btn-sm btn-primary' onClick={() => setLgreferShow(true)}>+</a></div>
                                                  
                                              </div>
                                            </div>
                                            
                                        {/* </form> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <br/>
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFive">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                      Shipping
                                    </button>
                                  </h2>
                                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    <div class="tab-pane active show" id="account" role="tabpanel" aria-labelledby="account-tab">
                                      {/* <form className="needs-validation user-add" novalidate=""> */}
                                            
                                            <div className="form-group">
                                              <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span>*</span>Shipping Local</label>
                                                </div>
                                                <div className="col">
                                                <input className="form-control" id="validationCustom0" type="text" required="" name='shplcl'/>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="form-group mt-2">
                                            <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span>*</span> Shipping Abroad</label>
                                                </div>
                                                <div className="col">
                                                <input className="form-control" id="validationCustom0" type="text" required="" name='shpabrd'/>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="form-group mt-2">
                                            <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span>*</span>Shipping Method</label>
                                                </div>
                                                <div className="col">
                                                <input className="form-control" id="validationCustom0" type="text" required="" name='shpmtd'/>
                                                </div>
                                              </div>
                                            </div>
                                            
                                        {/* </form> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <br/>
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingSix">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                      Description
                                    </button>
                                  </h2>
                                  <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    <div class="tab-pane active show" id="account" role="tabpanel" aria-labelledby="account-tab">
                                      {/* <form className="needs-validation user-add" novalidate=""> */}
                                            
                                            <div className="form-group">
                                              <div className="row">
                                                <AddFields onValueChange={handleChildValue}/>
                                              </div>
                                            </div>

                                            {/*  */}
                                            
                                       {/*  </form> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <br/>
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingSeven">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                      Keywords
                                    </button>
                                  </h2>
                                  <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    <div class="tab-pane active show" id="account" role="tabpanel" aria-labelledby="account-tab">
                                      {/* <form className="needs-validation user-add" novalidate=""> */}
                                            
                                            <div className="form-group">
                                              <div className="row">
                                                <div className="col">
                                                <label for="validationCustom0"><span>*</span> Keyword</label>
                                                </div>
                                                <div className="col">
                                               {console.log("expertoptions----------------->",expertusers)}
                                                  <MultiSelect
                                                      options={keywordsDataoptions}
                                                      value={selectedKwd}
                                                      onChange={setSelectedKwd}
                                                      labelledBy="Select"
                                                      id="reference"
                                                    />
                                              </div>
                                              <div className="col"><a className='btn btn-sm btn-primary' onClick={() => setLgkeywordShow(true)}>+</a></div>
                                              </div>
                                            </div>
                                       {/*  </form> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                            {/*  */}
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-6">
                <div className="card">
                    <div className="card-header">
                        <h5>Add Description</h5>
                    </div>
                    <div className="card-body">
                        <div className="digital-add needs-validation">
                        <div className="form-group">
                                
                                {/* <Editor editorState={editorState} onChange={onChange} /> */}
                                <Texteditor editorState={editorState} onEditorStateChange={handleEditorChange}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        <h5>Image</h5>
                    </div>
                    <div className="card-body">
                        <div className="digital-add needs-validation">
                            {/* <div className="form-group">
                                <label for="validationCustom05" className="col-form-label pt-0"> Meta Title</label>
                                <input className="form-control" id="validationCustom05" type="text" required=""/>
                            </div> */}
                            <div className="form-group">
                            <label className="col-form-label pt-0"> Product Upload</label>
                            <div className="dropzone digits" id="singleFileUpload">
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                  <p>Drop the files here ...</p>
                                ) : (
                                  <p>Drag 'n' drop some files here, or click to select files</p>
                                )}
                              </div>
                              <div className="row">
                                {files.map((file, index) => (
                                  <div key={index} className="col-2">
                                    <img src={file.preview} alt="Preview" className='dragimg-sz'/>
                                    <p>{file.name}</p>
                                  </div>
                                ))}
                              </div>
                              
                            </div>
                            </div>
                            <br/>
                            
                        </div>
                      
                    </div>
                </div>
            </div>
            <div className="row mt-2">
              <div className="col-12">
                <div className="card">
                <div className="card-body">
                  <div className='digital-add needs-validation'>
                    <div className="form-group mb-0">
                        <div className="product-buttons text-center">
                          <button type="submit" className="btn btn-primary">Submit</button>
                          &nbsp;&nbsp;&nbsp;<button type="button" className="btn btn-light">Discard</button>
                        </div>
                      </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        </form>
      </div>
      </div>
      {/* model */}
     {/*  <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Large modal</button> */}

        <div ref={modalRef} className="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Add New Category Master</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form onSubmit={handleSubmitCategory}>
                <div className="modal-body">
                  
                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label">Category <span className="required">*</span></label>
                      <input type="text" className="form-control" id="recipient-name" name='category' placeholder='Category'/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label">Order By </label>
                      <input type="text" className="form-control" id="recipient-name" name='order' placeholder='Order '/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label">Level <span className="required">*</span></label>
                      <select className="form-control" name='level'>
                        <option value="" selected>Please Select</option>
                        <option value="Category Group">Category Group</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label">Status <span className="required">*</span></label>
                      <select className="form-control" name='status' >
                        <option value="" selected>Please Select</option>
                        <option value="Active">Active</option>
                        <option value="Freezed">Freezed</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label">Category IMG <span className="required">*</span></label>
                      <input type="file" className="form-control" id="recipient-name"/>
                    </div>

                  
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Save</button>
                </div>
                </form>
              </div>
          </div>
        </div>

        {/* Tree modal */}
        <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                Add Category Master
            </Modal.Title>
            </Modal.Header>
            <form ref={formRef} onSubmit={handleSubmitCategory}>
            <Modal.Body>
                <div className="modal-body">
                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label">Parent ID <span className="required">*</span></label>
                      <input type="text" className="form-control" id="parent_cat_id" name='parentcategory' readOnly placeholder='Category' value={groupselectedVal}/>

                      <input type="hidden" className="form-control" id="parent_cat_id" name='parent_id' placeholder='Category' value={groupselectedKey}/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Category Name</strong></label>
                      <input type="text" className="form-control" id="recipient-name" name='category_master' placeholder='Master Category '/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Level</strong> <span className="required">*</span></label>
                      <select className="form-control" name='level'>
                        <option value="Category Master">Category Master</option>
                      </select>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Save</button>
            </Modal.Footer>
            </form>
        </Modal>

        <Modal
            size="lg"
            show={lgShow2}
            onHide={() => setLgShow2(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
               Category Main
            </Modal.Title>
            </Modal.Header>
            <form ref={formRefmain} onSubmit={handleSubmitCategorymain}>
            <Modal.Body>
            <div className="modal-body">
                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Parent ID </strong><span className="required">*</span></label>
                      <input type="text" className="form-control" id="parent_cat_id" name='parentcategory' readOnly placeholder='Category' value={masterselectedVal}/>

                      <input type="hidden" className="form-control" id="parent_cat_id" name='parent_id' placeholder='Category' value={masterselectedKey}/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Category Name</strong></label>
                      <input type="text" className="form-control" id="recipient-name" name='category_main' placeholder='Main Category'/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Gst Category</strong> <span className="required">*</span></label>
                      {console.log("gst all",gstAll)}
                      {(gstAll.Success) && <select className="form-control" name='gst_id'>
                        <option value="" selected disabled>Please Select</option>
                            {gstAll.resultData.map((gst, index) => (
                                <option key={index} value={gst._id}>
                                    {gst.category},{gst.rate},{gst.hsn}
                                </option>
                            ))}
                      </select>}
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Meta Description</strong></label>
                      <input type="text" className="form-control" id="recipient-name" name='meta_desc' />
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Level</strong> <span className="required">*</span></label>
                      <select className="form-control" name="level">
                        <option value="Category Main">Category Main</option>
                      </select>
                    </div>

                    

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Save</button>
            </Modal.Footer>
            </form>
        </Modal>

        <Modal
            size="lg"
            show={lgShow3}
            onHide={() => setLgShow3(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                Sub Category
            </Modal.Title>
            </Modal.Header>
            <form ref={formRefsub} onSubmit={handleSubmitCategorysub}>
            <Modal.Body>
                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Parent ID </strong><span className="required">*</span></label>
                     
                      <input type="text" className="form-control" id="parent_cat_id" name='parentcategory' readOnly placeholder='Category' value={mainerselectedVal}/>

                      <input type="hidden" className="form-control" id="parent_cat_id" name='parent_id' placeholder='Category' value={mainselectedKey}/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Category Name</strong></label>
                      <input type="text" className="form-control" id="recipient-name" name='category' placeholder='Category'/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Level</strong> <span className="required">*</span></label>
                      <select className="form-control" name="level">
                        <option value="sub1">Sub Category</option>
                      </select>
                    </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary">Save</button>
                
            </Modal.Footer>
            </form>
        </Modal>

        <Modal
            size="lg"
            show={lgShow4}
            onHide={() => setLgShow4(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                Large Modalsdf
            </Modal.Title>
            </Modal.Header>
            <form  ref={formRefsub1} onSubmit={handleSubmitCategorysubone}>
            <Modal.Body>
                <div className="form-group">
                  <label for="recipient-name" className="col-form-label"><strong>Parent ID </strong><span className="required">*</span></label>
                    
                    <input type="text" className="form-control" id="parent_cat_id" name='parentcategory' readOnly placeholder='Category' value={subselectedVal}/>

                    <input type="hidden" className="form-control" id="parent_cat_id" name='parent_id' placeholder='Category' value={subselectedKey}/>
                </div>

                <div className="form-group">
                  <label for="recipient-name" className="col-form-label"><strong>Category Name</strong></label>
                  <input type="text" className="form-control" id="recipient-name" name='category' placeholder='Category'/>
                </div>

                <div className="form-group">
                  <label for="recipient-name" className="col-form-label"><strong>Level</strong> <span className="required">*</span></label>
                  <select className="form-control" name="level">
                    <option value="sub2">Sub Category</option>
                  </select>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary">Save</button>
                
            </Modal.Footer>
            </form>
        </Modal>

        <Modal
            size="lg"
            show={lgShow5}
            onHide={() => setLgShow5(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                Add Subcategory
            </Modal.Title>
            </Modal.Header>
            <form  ref={formRefsub2} onSubmit={handleSubmitCategorysubtwo}>
            <Modal.Body>
                <div className="form-group">
                  <label for="recipient-name" className="col-form-label"><strong>Parent ID </strong><span className="required">*</span></label>
                     
                    <input type="text" className="form-control" id="parent_cat_id" name='parentcategory' readOnly placeholder='Category' value={suboneerselectedVal}/>

                    <input type="hidden" className="form-control" id="parent_cat_id" name='parent_id' placeholder='Category' value={suboneselectedKey}/>
                </div>

                <div className="form-group">
                  <label for="recipient-name" className="col-form-label"><strong>Category Name</strong></label>
                  <input type="text" className="form-control" id="recipient-name" name='category' placeholder='Category'/>
                </div>

                <div className="form-group">
                  <label for="recipient-name" className="col-form-label"><strong>Level</strong> <span className="required">*</span></label>
                  <select className="form-control" name="level">
                    <option value="sub3">Sub Category</option>
                  </select>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary">Save</button>
                
            </Modal.Footer>
            </form>
        </Modal>
        {/* End */}
      </div>
    </>
  )
}

