import logo from './logo.svg';
import CommonLayout from './components/CommonLayout';
import DashboardState from './context/dashboardState';
import Login from './components/Login';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

function App() {
  return (
    <>
    <DashboardState>
      <BrowserRouter>
          <Routes>
            <Route exact path="/login" element={<Login key = 'login'/>}/>
            <Route exact index element={<CommonLayout key="common" page ="dashboard"/>}/>
            <Route exact path="/showProducts" element={<CommonLayout key="showProducts" page ="showProducts"/>}/>
            <Route exact path="/categoryGroup" element={<CommonLayout key="categoryGroup" page ="CategoryGroup"/>}/>
            <Route exact path="/categoryMaster" element={<CommonLayout key="categoryMaster" page ="categoryMaster"/>}/>
            <Route exact path="/gstcategory" element={<CommonLayout key="gstcategory" page ="gstcategory"/>}/>
            <Route exact path="/addUnlistedproduct" element={<CommonLayout key="addUnlistedproduct" page ="addUnlistedproduct"/>}/>
            <Route
              exact
              path="/addcdnumber"
              element={<CommonLayout key="addcdnumber" page="addcdnumber" />}
            />
            <Route
              exact
              path="/productcondition"
              element={
                <CommonLayout key="productcondition" page="productcondition" />
              }
            />
            <Route
              exact
              path="/headdescription"
              element={
                <CommonLayout key="headdescription" page="headdescription" />
              }
            />
            <Route
              exact
              path="/userinfo"
              element={
                <CommonLayout key="allusers" page="allusers" />
              }
            />
            <Route
              exact
              path="/consignor"
              element={
                <CommonLayout key="consignor" page="consignor" />
              }
            />
            <Route
              exact
              path="/expertuser"
              element={
                <CommonLayout key="expertuser" page="expertuser" />
              }
            />


            <Route
              exact
              path="/references"
              element={
                <CommonLayout key="references" page="references" />
              }
            />
            <Route
              exact
              path="/addheaddescription"
              element={
                <CommonLayout key="addheaddescription" page="addheaddescription" />
              }
            />





            <Route
              exact
              path="/cateogryhome"
              element={
                <CommonLayout key="cateogryhome" page="cateogryhome" />
              }
            />
            <Route
              exact
              path="/cateogrymaster"
              element={
                <CommonLayout key="cateogrymaster" page="cateogrymaster" />
              }
            />
            <Route
              exact
              path="/cateogrymain"
              element={
                <CommonLayout key="cateogrymain" page="cateogrymain" />
              }
            />

            <Route
              exact
              path="/cateogrysub"
              element={
                <CommonLayout key="cateogrysub" page="cateogrysub" />
              }
            />
             <Route
              exact
              path="/cateogrysub2"
              element={
                <CommonLayout key="cateogrysub2" page="cateogrysub2" />
              }
            />
             <Route
              exact
              path="/cateogrysub3"
              element={
                <CommonLayout key="cateogrysub3" page="cateogrysub3" />
              }
            />
            
            
          </Routes>
        </BrowserRouter>
      </DashboardState>
    </>
  );
}

export default App;
