import React,{useState,useContext} from 'react'
import DashboardContext from "../../context/DashboardContext";

export default function Userpermissions(props) {
    const a = useContext(DashboardContext);
    const {set_permission,userData} = a;
    const userperm = props.data;
    console.log("user param------------------>",userperm);
    /* const { permissiondata } = a; */
    /* const [isChecked, setIsChecked] = useState(false); */
    const [isCheckedEmail, setisCheckedEmail] = useState((userperm[0].is_email_verified)?true:false);
    const [isCheckedPhone, setisCheckedPhone] = useState((userperm[0].is_mobile_verified)?true:false);
    const [isCheckedReference, setisCheckedReference] = useState((userperm[0].is_reference_verified)?true:false);
    const [isCheckedreftype, setisCheckedreftype] = useState(userperm[0].reference_type);
    const [isCheckedinfo, setisCheckedinfo] = useState((userperm[0].is_info_verified)?true:false);
    const [isCheckedtrust, setisCheckedtrust] = useState((userperm[0].is_trusted_user)?true:false);
    const [isCheckedExpert, setisCheckedExpert] = useState((userperm[0].is_expert_user)?true:false);
    const [isCheckedCons, setisCheckedCons] = useState((userperm[0].is_consigner_user)?true:false);
    const [isChecked, setIsChecked] = useState((userperm[0].is_proxy_user)?true:false);
    /* const [isChecked, setIsChecked] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isChecked, setIsChecked] = useState(false); */

    const handleCheckboxChangeemail = () => {
        setisCheckedEmail(!isCheckedEmail);
    };
    const handleCheckboxChangephn = () => {
        setisCheckedPhone(!isCheckedPhone);
    };
    const handleCheckboxChangeref = () => {
        setisCheckedReference(!isCheckedReference);
    };
    const handleCheckboxChangeinfo = () => {
        setisCheckedinfo(!isCheckedinfo);
    };
    const handleCheckboxChangetru = () => {
        setisCheckedtrust(!isCheckedtrust);
    };
    const handleCheckboxChangeexp = () => {
        setisCheckedExpert(!isCheckedExpert);
    };
    const handleCheckboxChangecon = () => {
        setisCheckedCons(!isCheckedCons);
    };
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const handleSubmitpermission = ()=>{
        set_permission(userperm[0].userID,isCheckedEmail,isCheckedPhone,isCheckedReference,isCheckedinfo,isCheckedtrust,isCheckedExpert,isCheckedCons,isChecked);
    }

  return (
    <>
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-2">
                    <div className="card">
                        <div className="card-body">
                            <div className="profile-details text-center">
                                {/* <img src="../assets/images/dashboard/designer.jpg" alt="" className="img-fluid img-90 rounded-circle blur-up lazyloaded"/> */}
                                <h5 className="f-w-600 mb-0">{userData.fullname}</h5>
                                <span>{userData.email}</span>
                                {/* <div className="social">
                                    <div className="form-group btn-showcase">
                                        <button className="btn social-btn btn-fb d-inline-block"> <i className="fa fa-facebook"></i></button>
                                        <button className="btn social-btn btn-twitter d-inline-block"><i className="fa fa-google"></i></button>
                                        <button className="btn social-btn btn-google d-inline-block mr-0"><i className="fa fa-twitter"></i></button>
                                    </div>
                                </div> */}
                            </div>
                            <hr/>
                            <div className="project-status">
                                <h5 className="f-w-600">User Details</h5>
                                <div className="media">
                                    <div className="media-body">
                                    <h6>Lable 1<span className="pull-right">Detail 1</span></h6>
                                        <div className="progress sm-progress-bar">
                                            <div className="progress-bar bg-primary" role="progressbar"/*  style="width: 90%" */ aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="media">
                                    <div className="media-body">
                                    <h6>Lable 2<span className="pull-right">Detail 2</span></h6>
                                        <div className="progress sm-progress-bar">
                                            <div className="progress-bar bg-secondary" role="progressbar" /* style="width: 60%"  */aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="media">
                                    <div className="media-body">
                                        <h6>Lable 3<span className="pull-right">Detail 3</span></h6>
                                        <div className="progress sm-progress-bar">
                                            <div className="progress-bar bg-danger" role="progressbar" /* style="width: 70%" */ aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-10">
                    <div className="card tab2-card">
                        <div className="card-body">
                            <ul className="nav nav-tabs nav-material" id="top-tab" role="tablist">
                                <li className="nav-item"><a className="nav-link active" id="top-profile-tab" data-toggle="tab" href="#top-profile" role="tab" aria-controls="top-profile" aria-selected="true"><i data-feather="user" className="mr-2"></i>Permission</a>
                                </li>
                                {/* <li className="nav-item"><a className="nav-link" id="contact-top-tab" data-toggle="tab" href="#top-contact" role="tab" aria-controls="top-contact" aria-selected="false"><i data-feather="settings" className="mr-2"></i>Contact</a>
                                </li> */}
                            </ul>
                            <div className="tab-content" id="top-tabContent">
                                <div className="tab-pane fade show active" id="top-profile" role="tabpanel" aria-labelledby="top-profile-tab">
                                    <h5 className="f-w-600">Set Permission</h5>
                                    <div className="account-setting">
                                        {/* <h5 className="f-w-600">Notifications</h5> */}
                                        <div className="row">
                                            <div className="col">
                                                <label className="d-block" for="chk-ani">
                                                    <input className="checkbox_animated" id="chk-ani" type="checkbox" checked={isCheckedEmail} onChange={handleCheckboxChangeemail}/>
                                                    Email Verfied
                                                </label>
                                                <br/>
                                                <label className="d-block" for="chk-ani1">
                                                    <input className="checkbox_animated" id="chk-ani1" type="checkbox" checked={isCheckedPhone} onChange={handleCheckboxChangephn}/>
                                                    Mobile Verfied
                                                </label>
                                                <br/>
                                                <label className="d-block" for="chk-ani2">
                                                    <input className="checkbox_animated" id="chk-ani2" type="checkbox" checked={isCheckedReference} onChange={handleCheckboxChangeref}/>
                                                   Reference Verified
                                                </label>
                                                <br/>
                                                <div className="form-group m-checkbox-inline mb-0 custom-radio-ml radio-animated">
                                                    <div className='row'>
                                                        <div className='col-4'>
                                                            <label className='mr-4'><strong>Reference Type</strong></label>
                                                        </div>
                                                        <div className='col-8'>
                                                            <label className='radio_permission' for="edo-ani1">
                                                            <input className="radio_animated " id="edo-ani1" type="radio" name="rdo-ani" checked={(isCheckedreftype == 0)?true:false} value='0'/>
                                                                Reference Not Verified
                                                            </label>
                                                            <label></label>
                                                            <label className='radio_permission' for="edo-ani2">
                                                                <input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" checked={(isCheckedreftype == 1)?true:false} value='1'/>
                                                                Persional Reference
                                                            </label>
                                                            <label></label>
                                                            <label className='radio_permission' for="edo-ani3">
                                                                <input className="radio_animated" id="edo-ani3" type="radio" name="rdo-ani" checked={(isCheckedreftype == 2)?true:false} value='2'/>
                                                                Paid Membership
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className='row'>
                                                        <div className='col-4'>
                                                            <label className='mr-4'><strong>Remark</strong></label>
                                                        </div>
                                                        <div className='col-8'>
                                                            <textarea className='form-control' placeholder='Remark'></textarea>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className='row'>
                                                        <div className='col-4'>
                                                            <label className='mr-4'><strong>Limit Amount</strong></label>
                                                        </div>
                                                        <div className='col-8'>
                                                            <input type="number" className='form-control' name="limit_amount" placeholder='Amount'></input>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                
                                                <br/>
                                                <label className="d-block" for="chk-ani4">
                                                    <input className="checkbox_animated" id="chk-ani4" type="checkbox" checked={isCheckedinfo} onChange={handleCheckboxChangeinfo}/>
                                                    Legal info Verified
                                                </label>
                                                <br/>
                                                
                                                <label className="d-block" for="chk-ani6">
                                                    <input className="checkbox_animated" id="chk-ani6" type="checkbox" checked={isCheckedtrust} onChange={handleCheckboxChangetru}/>
                                                   Trusted User
                                                </label>
                                                <br/>
                                                <label className="d-block mb-0" for="chk-ani7">
                                                    <input className="checkbox_animated" id="chk-ani7" type="checkbox" checked={isCheckedExpert} onChange={handleCheckboxChangeexp}/>
                                                    Expert User
                                                </label>
                                                <br/>
                                                <label className="d-block mb-0" for="chk-ani8">
                                                    <input className="checkbox_animated" id="chk-ani8" type="checkbox" checked={isCheckedCons} onChange={handleCheckboxChangecon}/>
                                                    Consignor User
                                                </label>
                                                <br/>
                                                <label className="d-block mb-0" for="chk-ani9">
                                                    <input className="checkbox_animated" id="chk-ani9" type="checkbox" checked={isChecked} onChange={handleCheckboxChange}/>
                                                    Proxy User
                                                </label>
                                                
                                                
                                            </div>
                                            
                                        </div>
                                        <br/>
                                        <div className='row'>
                                            <div className="col-4">
                                            <label className="d-block mb-0 bold">
                                                <strong>Login Status</strong>
                                            </label>
                                            </div>
                                            <div className="col-8">
                                                <select className='form-control'>
                                                    <option value="">Select Login status</option>
                                                    <option value="1" selected={userperm.login_status?'true':'false'}>Active</option>
                                                    <option value="0" >Freez</option>
                                                </select>
                                            </div>
                                            
                                        </div>
                                        <br/>
                                        <div className='row'>
                                            <div className="col-4">
                                            <label className="d-block mb-0 bold">
                                                <strong>Consigner Commission</strong>
                                            </label>
                                            </div>
                                            <div className="col-8">
                                                <input className='form-control' placeholder='commission in %' name='consigner_comm'/>
                                            </div>
                                            
                                        </div>

                                        <br/>
                                        <div className='row'>
                                            <div className="col-4">
                                            <label className="d-block mb-0 bold">
                                                <strong>Builder Commission PC (in %)</strong>
                                            </label>
                                            </div>
                                            <div className="col-8">
                                                <input className='form-control' placeholder='commission in %' name='consigner_comm_pc'/>
                                            </div>
                                            
                                        </div>

                                        <br/>
                                        <div className='row'>
                                            <div className="col-4">
                                            <label className="d-block mb-0 bold">
                                                <strong>Builder Commission Mobile (in %)</strong>
                                            </label>
                                            </div>
                                            <div className="col-8">
                                                <input className='form-control' placeholder='commission in %' name='consigner_comm_mb'/>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                                <br/>
                                <br/>
                                <div className="row">
                                    <div className="col-12 ">
                                        <button className='btn btn-sm btn-primary rghtfloat' onClick={handleSubmitpermission}>Save</button>
                                        <button className='btn btn-sm btn-danger rghtfloat' >Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
