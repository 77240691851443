import React,{useContext,useRef,useEffect,useState} from 'react'
import GSTtable from '../lookup/GSTtable';
import DashboardContext from '../../context/DashboardContext';
import Modal from 'react-bootstrap/Modal';

export default function GSTcategory() {

  const [lgShow, setLgShow] = useState(false);
  const a = useContext(DashboardContext);
  const modalRef = useRef(null);
  const {addgst,fetchAllgst,gstAll} = a;
  /* const [first, setfirst] = useState(categoryGroup) */
  const handleSubmitCategory = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let category = data.get('category');
    let rate = data.get('rate');
    let hsn = data.get('hsn');
    let status = data.get('status');

    console.log("status",status);
    addgst(category,rate,hsn,status);

    modalRef.current.reset();
    setLgShow(false)

  }

  

  useEffect(() => {
    console.log("user",localStorage.getItem('is_login'));
    fetchAllgst();
    // eslint-disable-next-line
  }, [])
  

  return (
    <>
      <div className="page-body">
        <div className="container-fluid">
            <div className="page-header">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="page-header-left">
                            <h3>Tax info
                                <small>MarudharArts Admin panel</small>
                            </h3>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <ol className="breadcrumb pull-right">
                            <li className="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li>
                            <li className="breadcrumb-iteem">Dashboard/</li>
                            <li className="breadcrumb-itm active">GST Category</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <div className="row">
                      <div className="col-sm-6">
                        <h5>GST Category</h5>
                      </div>
                      <div className="col-sm-6">
                        <button className="btn btn-sm btn-primary start_left" onClick={() => setLgShow(true)}>Add</button>
                      </div>
                    </div>
                   
                </div>
                <div className="card-body">
                  {gstAll.Success && <GSTtable data={gstAll.resultData}/>}
                </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      {/* model */}
     {/*  <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Large modal</button> */}

        <div ref={modalRef} className="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel"><strong>Add GST Category</strong></h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form onSubmit={handleSubmitCategory}>
                <div className="modal-body">
                  
                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Category</strong> <span className="required">*</span></label>
                      <input type="text" className="form-control" id="recipient-name" name='category' placeholder='Category'/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>@</strong> </label>
                      <input type="text" className="form-control" id="recipient-name" name='rate' placeholder=''/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>HSN Code</strong>  <span className="required">*</span></label>
                      <input type="text" className="form-control" id="recipient-name" name='hsn' placeholder='HSN Code'/>
                      
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Status</strong> <span className="required">*</span></label>
                      <select className="form-control" name='status' >
                        <option value="" selected>Please Select</option>
                        <option value="Active">Active</option>
                        <option value="Freezed">Freezed</option>
                      </select>
                    </div>

                  
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Save</button>
                </div>
                </form>
              </div>
          </div>
        </div>
      </div>


      <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                Add Subcategory
            </Modal.Title>
            </Modal.Header>
            <form  ref={modalRef} onSubmit={handleSubmitCategory}>
            <Modal.Body>
                <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Category</strong> <span className="required">*</span></label>
                      <input type="text" className="form-control" id="recipient-name" name='category' placeholder='Category'/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>@</strong> </label>
                      <input type="text" className="form-control" id="recipient-name" name='rate' placeholder=''/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>HSN Code</strong>  <span className="required">*</span></label>
                      <input type="text" className="form-control" id="recipient-name" name='hsn' placeholder='HSN Code'/>
                      
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Status</strong> <span className="required">*</span></label>
                      <select className="form-control" name='status' >
                        <option value="" selected>Please Select</option>
                        <option value="Active">Active</option>
                        <option value="Freezed">Freezed</option>
                      </select>
                    </div>
            </Modal.Body>
            <Modal.Footer>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Save</button>
                
            </Modal.Footer>
            </form>
        </Modal>
    </>
  )
}
