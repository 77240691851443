import React, { useState,useContext } from 'react';
import AdminLayout from './AdminLayout';
import Login from './Login';
import DashboardContext from '../context/DashboardContext';


export default function CommonLayout(props) {
  console.log("props vies",props.page);

  const a = useContext(DashboardContext);

  const {islogin,userLogin} = a;
  


  return (
    <>
      {islogin?<AdminLayout page = {props.page}/>:<Login/>}
    </>
  )
}
