import React,{useContext,useRef,useEffect,useState} from 'react'
import Allusers from '../Allusers';
import DashboardContext from '../../context/DashboardContext';
import Modal from 'react-bootstrap/Modal';


export default function CategoryGroup() {

  const [lgShow, setLgShow] = useState(false);
  const a = useContext(DashboardContext);
  const modalRef = useRef(null);
  const {addCategoryGroup,getCategoryGroup,categoryGroup} = a;
  /* const [first, setfirst] = useState(categoryGroup) */
  const handleSubmitCategory = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let category = data.get('category');
    let order = data.get('order');
    let level = data.get('level');
    let status = data.get('status');

    console.log("status",status);
    addCategoryGroup(category,order,level,status);

    modalRef.current.reset();
    setLgShow(false)

  }

  useEffect(() => {
    console.log("user",localStorage.getItem('is_login'));
    getCategoryGroup();
    // eslint-disable-next-line
  }, [])
  

  return (
    <>
      <div className="page-body">
        <div className="container-fluid">
            <div className="page-header">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="page-header-left">
                            <h3>Categories
                                <small>MarudharArts Admin panel</small>
                            </h3>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <ol className="breadcrumb pull-right">
                            <li className="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li>
                            <li className="breadcrumb-iteem">Dashboard</li>
                            <li className="breadcrumb-itm active">Products</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <div className="row">
                      <div className="col-sm-6">
                        <h5>Category Group</h5>
                      </div>
                      <div className="col-sm-6">
                        <button className="btn btn-sm btn-primary start_left" onClick={() => setLgShow(true)}>Add</button>
                      </div>
                    </div>
                   
                </div>
                <div className="card-body">
                  <Allusers data={categoryGroup}/>
                </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      {/* model */}
     {/*  <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Large modal</button> */}

       

          <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                Add Subcategory
            </Modal.Title>
            </Modal.Header>
            <form  ref={modalRef} onSubmit={handleSubmitCategory}>
            <Modal.Body>
                <div className="form-group">
                  <label for="recipient-name" className="col-form-label">Category <span className="required">*</span></label>
                  <input type="text" className="form-control" id="recipient-name" name='category' placeholder='Category'/>
                </div>

                <div className="form-group">
                  <label for="recipient-name" className="col-form-label">Order By </label>
                  <input type="text" className="form-control" id="recipient-name" name='order' placeholder='Order '/>
                </div>

                <div className="form-group">
                  <label for="recipient-name" className="col-form-label">Level <span className="required">*</span></label>
                  <select className="form-control" name='level'>
                    <option value="" selected>Please Select</option>
                    <option value="Category Group">Category Group</option>
                  </select>
                </div>

                <div className="form-group">
                  <label for="recipient-name" className="col-form-label">Status <span className="required">*</span></label>
                  <select className="form-control" name='status' >
                    <option value="" selected>Please Select</option>
                    <option value="Active">Active</option>
                    <option value="Freezed">Freezed</option>
                  </select>
                </div>

                <div className="form-group">
                  <label for="recipient-name" className="col-form-label">Category IMG <span className="required">*</span></label>
                  <input type="file" className="form-control" id="recipient-name"/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Save</button>
                
            </Modal.Footer>
            </form>
        </Modal>
    </>
  )
}
