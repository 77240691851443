import React from 'react'
import Footer from './common/Footer';
import Dashboard from './Dashboard';
import Header from './common/Header';
import ShowProducts from './unlisted/ShowProducts';
import CategoryGroup from './categories/CategoryGroup';
import CategoryMaster from './categories/CategoryMaster';
import GSTcategory from './categories/GSTcategory';
import AddUnlistedProducts from './unlisted/AddUnlistedProducts';
import AddCDNumber from "./userinfo/consignerinfo/AddCDNumber";
import ProductCondition from "./product&cat/ProductCondtion";
import HeadDescription from "./product&cat/productdescription/HeadDescription";
import AddHeadDescription from "./productdescription/AddHeadDescription";
import Allusers from './userinfo/consignerinfo/Allusers';
import Reference from './References';
import Cateogryhome from './categories/Cateogryhome';
import Cateogrymaster from './categories/CategoryMaster';
import Cateogrysub from './categories/Cateogrysub';
import Cateogrysub2 from './categories/Cateogysub2';
import Cateogrysub3 from './categories/Cateogysub3';
import MainCateogry from './categories/MainCateogry';
import UserConsignor from './userinfo/consignerinfo/UserConsignor'/* consignor */
import Expert from './userinfo/Expert';

export default function AdminLayout(props) {
    const mainpage = props.page
  return (
    <>
        <div className="page-wrapper">
        
            <Header/>
            <div className="page-body-wrapper">
                {mainpage==='dashboard' && <Dashboard/>}
                {mainpage==='showProducts' && <ShowProducts/>}
                {mainpage==='CategoryGroup' && <CategoryGroup/>}
                {mainpage==='categoryMaster' && <CategoryMaster/>}
                {mainpage==='gstcategory' && <GSTcategory/>}
                {mainpage==='addUnlistedproduct' && <AddUnlistedProducts/>}
                {mainpage === "addcdnumber" && <AddCDNumber />}
                {mainpage === "productcondition" && <ProductCondition />}
                {mainpage === "headdescription" && <HeadDescription />}
                {mainpage === "addheaddescription" && <AddHeadDescription />}
                
                {mainpage === "allusers" && <Allusers/>}
                {mainpage === "references" && <Reference/>}

                {mainpage === "cateogryhome" && <Cateogryhome />}
                
                {mainpage === "cateogrymaster" && <Cateogrymaster />}
                {mainpage === "cateogrymain" && <MainCateogry />}
                {mainpage === "cateogrysub" && <Cateogrysub />}
                {mainpage === "cateogrysub2" && <Cateogrysub2 />}
                {mainpage === "cateogrysub3" && <Cateogrysub3 />}
                {mainpage === "consignor" && <UserConsignor />}
                {mainpage === "expertuser" && <Expert />}
            <Footer/>
            </div>
            
        </div>
    </>
  )
}
