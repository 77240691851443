
import React, { useContext, useRef, useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";

import DashboardContext from "../context/DashboardContext";
//import Expert from "./Expert";
// import HeadDescriptionTable from "../lookup/HeadDescriptiontable";

//Multi Select Options value for expert
const expertoptions = [
  {
    label: "Maru Archie, 8088371888, mind5",
    value: "Maru Archie, 8088371888, mind5",
  },
  {
    label: "Marudhar Pro Arts, 924314599, mind1",
    value: "Marudhar Pro Arts, 924314599, mind1",
  },
  {
    label: "Rajender Maru, 9514910563112, mind3",
    value: "Rajender Maru, 9514910563112, mind3",
    disabled: true, // Use this attribute to disable any select
  },
  {
    label: "Rajender Pro, 9243112, mind2",
    value: "Rajender Pro, 9243112, mind2",
  },
];

//Multi Select Options value for Reference
const referenceoptions = [
  {
    label:
      "A Catalogue Of The INDIAN Coins In The British Museum By Edward James Rapson",
    value:
      "A Catalogue Of The INDIAN Coins In The British Museum By Edward James Rapson",
  },
  {
    label: "Akbar & His Coins By Mr. Dr. Pravin Parikh",
    value: "Akbar & His Coins By Mr. Dr. Pravin Parikh",
  },
  {
    label:
      "Ancient INDIAN Coins By Mr. Osmund Bopearachchi & Mr. Wilfried Pieper",
    value:
      "Ancient INDIAN Coins By Mr. Osmund Bopearachchi & Mr. Wilfried Pieper",
    disabled: true, // Use this attribute to disable any select
  },
  {
    label:
      "Ancient INDIAN Silver Punch Marked Coins of the Magadha - Maurya Karshapana Series By P. L. Gupta & ",
    value:
      "Ancient INDIAN Silver Punch Marked Coins of the Magadha - Maurya Karshapana Series By P. L. Gupta & ",
  },
];

export default function Reference() {
  const a = useContext(DashboardContext);
  const modalRef = useRef(null);
  const { addCategoryGroup, getCategoryGroup, categoryGroup } = a;

  //Use State Hook to Use MultiSelect
  const [selected, setSelected] = useState([]);

  /* const [first, setfirst] = useState(categoryGroup) */
  const handleSubmitCategory = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let category = data.get("category");
    let order = data.get("order");
    let level = data.get("level");
    let status = data.get("status");

    console.log("status", status);
    addCategoryGroup(category, order, level, status);

    if (modalRef.current) {
      modalRef.current.style.display = "none";
      document.body.classList.remove("modal-open");
      const modalBackdrop = document.querySelector(".modal-backdrop");
      if (modalBackdrop) {
        modalBackdrop.parentNode.removeChild(modalBackdrop);
      }
    }
  };

  useEffect(() => {
    console.log("user", localStorage.getItem("is_login"));
    getCategoryGroup();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="page-body">
        <div className="container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-lg-6">
                <div className="page-header-left">
                  <h3>
                    Products
                    <small>MarudharArts Admin panel</small>
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <ol className="breadcrumb pull-right">
                  <li className="breadcrumb-item">
                    <a href="index.html">
                      <i data-feather="home"></i>
                    </a>
                  </li>
                  <li className="breadcrumb-iteem">Dashboard</li>
                  <li className="breadcrumb-itm active">Products</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5>Head Description</h5>
                    </div>
                    <div className="col-sm-6">
                      <button
                        className="btn btn-sm btn-primary start_left"
                        data-toggle="modal"
                        data-target=".bd-example-modal-lg"
                      >
                        Expert
                      </button>
                      <button
                        className="btn btn-sm btn-primary start_left"
                        data-toggle="modal"
                        data-target=".reference-modal-lg"
                      >
                        Reference
                      </button>

                      <button
                        className="btn btn-sm btn-primary start_left"
                        data-toggle="modal"
                        data-target=".referenceupdate-modal-lg"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                <form onSubmit={handleSubmitCategory}>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="reference" className="col-form-label">
                      Reference <span className="required">*</span>
                    </label>
                    <MultiSelect
                      options={referenceoptions}
                      value={selected}
                      onChange={setSelected}
                      labelledBy="Select"
                      id="reference"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* model */}
      {/*  <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Large modal</button> */}

      <div
        ref={modalRef}
        className="modal fade bd-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Expert
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={handleSubmitCategory}>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="expert" className="col-form-label">
                      Expert <span className="required">*</span>
                    </label>
                    <MultiSelect
                      options={expertoptions}
                      value={selected}
                      onChange={setSelected}
                      labelledBy="Select"
                      id="expert"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        ref={modalRef}
        className="modal fade reference-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Reference
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
             
            </div>
          </div>
        </div>
      </div>

      <div
        ref={modalRef}
        className="modal fade referenceupdate-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Expert
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={handleSubmitCategory}>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="reference" className="col-form-label">
                      User <span className="required">*</span>
                    </label>
                    <select>
                      <option value="User1">User1</option>
                      <option value="User2">User2</option>
                      <option value="User3">User3</option>
                      <option value="User4">User4</option>
                      <option value="User5">User5</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="reference" className="col-form-label">
                      Expert <span className="required">*</span>
                    </label>
                    <select>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label for="Image-name" className="col-form-label">
                      Image <span className="required">*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="recipient-name"
                    />
                  </div>
                  <div className="form-group">
                    <label for="Image-name" className="col-form-label">
                      About <span className="required">*</span>
                    </label>
                    <textarea
                      className="form-control expert_about"
                      id="expert_about"
                      row="5"
                      cols="200"
                    ></textarea>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
