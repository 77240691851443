/* CategoryMaster */

import React,{useContext,useRef,useEffect,useState} from 'react'
import CateogryMastertable from '../lookup/CateogryMastertable';
import DashboardContext from '../../context/DashboardContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


export default function CategoryMaster() {


  const a = useContext(DashboardContext);
  const modalRef = useRef(null);
  /* const formRef = useRef(null); */

  const {getcategoryMasterdropdown,mastercategory} = a;

  /* const [first, setfirst] = useState(categoryGroup) */
  const handleSubmitCategory = (e) => {
    e.preventDefault();
    
    }

  /* if ( categoryMasteradd.Success) {
        
    } */
  useEffect(() => {
    console.log("user",localStorage.getItem('is_login'));
    getcategoryMasterdropdown('list');
    // eslint-disable-next-line
  }, [])
  

  return (
    <>
      <div className="page-body">
        <div className="container-fluid">
            <div className="page-header">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="page-header-left">
                            <h3>Categories
                                <small>MarudharArts Admin panel</small>
                            </h3>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <ol className="breadcrumb pull-right">
                            <li className="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li>
                            <li className="breadcrumb-iteem">Dashboard</li>
                            <li className="breadcrumb-itm active">Categories</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            
            <div className="card">
                <div className="card-header">
                    <div className="row">
                      <div className="col-sm-6">
                        <h5>Category Master</h5>
                      </div>
                      {/* <div className="col-sm-6">
                        <button className="btn btn-sm btn-primary start_left" data-toggle="modal" data-target=".bd-example-modal-lg">Add</button>
                      </div> */}
                    </div>
                   
                </div>
                <div className="card-body">
                  <CateogryMastertable data={mastercategory}/>
                </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      {/* model */}
     {/*  <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Large modal</button> */}

        <div ref={modalRef} className="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Add New Category Master</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form onSubmit={handleSubmitCategory}>
                <div className="modal-body">
                  
                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label">Category <span className="required">*</span></label>
                      <input type="text" className="form-control" id="recipient-name" name='category' placeholder='Category'/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label">Order By </label>
                      <input type="text" className="form-control" id="recipient-name" name='order' placeholder='Order '/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label">Level <span className="required">*</span></label>
                      <select className="form-control" name='level'>
                        <option value="" selected>Please Select</option>
                        <option value="Category Group">Category Group</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label">Status <span className="required">*</span></label>
                      <select className="form-control" name='status' >
                        <option value="" selected>Please Select</option>
                        <option value="Active">Active</option>
                        <option value="Freezed">Freezed</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label">Category IMG <span className="required">*</span></label>
                      <input type="file" className="form-control" id="recipient-name"/>
                    </div>

                  
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Save</button>
                </div>
                </form>
              </div>
          </div>
        </div>

        {/* Tree modal */}
        {/* <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                Add Category Master
            </Modal.Title>
            </Modal.Header>
            <form ref={formRef} onSubmit={handleSubmitCategory}>
            <Modal.Body>
                <div className="modal-body">
                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label">Parent ID <span className="required">*</span></label>
                      <input type="text" className="form-control" id="parent_cat_id" name='parentcategory' readOnly placeholder='Category' value={groupselectedVal}/>

                      <input type="hidden" className="form-control" id="parent_cat_id" name='parent_id' placeholder='Category' value={groupselectedKey}/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Category Name</strong></label>
                      <input type="text" className="form-control" id="recipient-name" name='category_master' placeholder='Master Category '/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Level</strong> <span className="required">*</span></label>
                      <select className="form-control" name='level'>
                        <option value="Category Master">Category Master</option>
                      </select>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Save</button>
            </Modal.Footer>
            </form>
        </Modal>

        <Modal
            size="lg"
            show={lgShow2}
            onHide={() => setLgShow2(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
               Category Main
            </Modal.Title>
            </Modal.Header>
            <form ref={formRefmain} onSubmit={handleSubmitCategorymain}>
            <Modal.Body>
            <div className="modal-body">
                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Parent ID </strong><span className="required">*</span></label>
                      <input type="text" className="form-control" id="parent_cat_id" name='parentcategory' readOnly placeholder='Category' value={masterselectedVal}/>

                      <input type="hidden" className="form-control" id="parent_cat_id" name='parent_id' placeholder='Category' value={masterselectedKey}/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Category Name</strong></label>
                      <input type="text" className="form-control" id="recipient-name" name='category_main' placeholder='Main Category'/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Gst Category</strong> <span className="required">*</span></label>
                      {console.log("gst all",gstAll)}
                      {(gstAll.Success) && <select className="form-control" name='gst_id'>
                        <option value="" selected disabled>Please Select</option>
                            {gstAll.resultData.map((gst, index) => (
                                <option key={index} value={gst._id}>
                                    {gst.category},{gst.rate},{gst.hsn}
                                </option>
                            ))}
                      </select>}
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Meta Description</strong></label>
                      <input type="text" className="form-control" id="recipient-name" name='meta_desc' />
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Level</strong> <span className="required">*</span></label>
                      <select className="form-control" name="level">
                        <option value="Category Main">Category Main</option>
                      </select>
                    </div>

                    

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Save</button>
            </Modal.Footer>
            </form>
        </Modal>

        <Modal
            size="lg"
            show={lgShow3}
            onHide={() => setLgShow3(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                Sub Category
            </Modal.Title>
            </Modal.Header>
            <form ref={formRefsub} onSubmit={handleSubmitCategorysub}>
            <Modal.Body>
                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Parent ID </strong><span className="required">*</span></label>
                     
                      <input type="text" className="form-control" id="parent_cat_id" name='parentcategory' readOnly placeholder='Category' value={mainerselectedVal}/>

                      <input type="hidden" className="form-control" id="parent_cat_id" name='parent_id' placeholder='Category' value={mainselectedKey}/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Category Name</strong></label>
                      <input type="text" className="form-control" id="recipient-name" name='category' placeholder='Category'/>
                    </div>

                    <div className="form-group">
                      <label for="recipient-name" className="col-form-label"><strong>Level</strong> <span className="required">*</span></label>
                      <select className="form-control" name="level">
                        <option value="sub1">Sub Category</option>
                      </select>
                    </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary">Save</button>
                
            </Modal.Footer>
            </form>
        </Modal>

        <Modal
            size="lg"
            show={lgShow4}
            onHide={() => setLgShow4(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                Large Modalsdf
            </Modal.Title>
            </Modal.Header>
            <form  ref={formRefsub1} onSubmit={handleSubmitCategorysubone}>
            <Modal.Body>
                <div className="form-group">
                  <label for="recipient-name" className="col-form-label"><strong>Parent ID </strong><span className="required">*</span></label>
                    
                    <input type="text" className="form-control" id="parent_cat_id" name='parentcategory' readOnly placeholder='Category' value={subselectedVal}/>

                    <input type="hidden" className="form-control" id="parent_cat_id" name='parent_id' placeholder='Category' value={subselectedKey}/>
                </div>

                <div className="form-group">
                  <label for="recipient-name" className="col-form-label"><strong>Category Name</strong></label>
                  <input type="text" className="form-control" id="recipient-name" name='category' placeholder='Category'/>
                </div>

                <div className="form-group">
                  <label for="recipient-name" className="col-form-label"><strong>Level</strong> <span className="required">*</span></label>
                  <select className="form-control" name="level">
                    <option value="sub2">Sub Category</option>
                  </select>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary">Save</button>
                
            </Modal.Footer>
            </form>
        </Modal>

        <Modal
            size="lg"
            show={lgShow5}
            onHide={() => setLgShow5(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                Large Modalsdfsdf  q
            </Modal.Title>
            </Modal.Header>
            <form  ref={formRefsub2} onSubmit={handleSubmitCategorysubtwo}>
            <Modal.Body>
                <div className="form-group">
                  <label for="recipient-name" className="col-form-label"><strong>Parent ID </strong><span className="required">*</span></label>
                     
                    <input type="text" className="form-control" id="parent_cat_id" name='parentcategory' readOnly placeholder='Category' value={suboneerselectedVal}/>

                    <input type="hidden" className="form-control" id="parent_cat_id" name='parent_id' placeholder='Category' value={suboneselectedKey}/>
                </div>

                <div className="form-group">
                  <label for="recipient-name" className="col-form-label"><strong>Category Name</strong></label>
                  <input type="text" className="form-control" id="recipient-name" name='category' placeholder='Category'/>
                </div>

                <div className="form-group">
                  <label for="recipient-name" className="col-form-label"><strong>Level</strong> <span className="required">*</span></label>
                  <select className="form-control" name="level">
                    <option value="sub3">Sub Category</option>
                  </select>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary">Save</button>
                
            </Modal.Footer>
            </form>
        </Modal> */}
        {/* End */}
      </div>
    </>
  )
}

