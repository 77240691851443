import React,{useContext,useEffect} from 'react'
import { useNavigate,Link } from 'react-router-dom';
import DashboardContext from '../context/DashboardContext';


export default function Login() {
  const a = useContext(DashboardContext);
  const navigate = useNavigate();
  const {islogin,userLogin} = a;
  console.log("userlogin",islogin);
  useEffect(() => {
    if(islogin)
    {
      navigate('/');
    }
    // eslint-disable-next-line
  }, [])
  
  function handleLogin(e) {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let username = data.get('username');
    let password = data.get('password');

    userLogin(username,password);
    console.log("userlogin",islogin);
  }

  return (
    <>
      <div className="page-wrapper">
    <div className="authentication-box">
        <div className="container">
            <div className="row">
                <div className="col-md-2 p-0 card-left">
                    <img ></img>       
                </div>
            </div>
            <div className="row">
                <div className="col-md-2 p-0 card-left">
                   
                </div>
                <div className="col-md-7 p-0 card-right">
                <div className="row">
                    
                    <div className="col-12">
                        <Link to="/">
                        <img className="blur-up lazyloaded logo_size mb-2 login_page_logo" src="./assets/images/dashboard/marudhar_logo.png" alt="" />
                        </Link>
                    </div>
                </div>
                
                    <div className="card tab2-card">
                        <div className="card-body">
                            <ul className="nav nav-tabs nav-material" id="top-tab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="top-profile-tab" data-toggle="tab" href="#top-profile" role="tab" aria-controls="top-profile" aria-selected="true"><span className="icon-user mr-2"></span>Login</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" id="contact-top-tab" data-toggle="tab" href="#top-contact" role="tab" aria-controls="top-contact" aria-selected="false"><span className="icon-unlock mr-2"></span>Register</a>
                                </li> */}
                            </ul>
                            <div className="tab-content" id="top-tabContent">
                                <div className="tab-pane fade show active" id="top-profile" role="tabpanel" aria-labelledby="top-profile-tab">
                                    <form className="form-horizontal auth-form" onSubmit={handleLogin}>
                                        <div className="form-group">
                                            <input required="" name="username" type="text" className="form-control" id="exampleInputEmail1"/>
                                        </div>
                                        <div className="form-group">
                                            <input required="" name="password" type="password" className="form-control" placeholder="Password"/>
                                        </div>
                                        <div className="form-terms">
                                            <div className="custom-control custom-checkbox mr-sm-2">
                                                <input type="checkbox" className="custom-control-input" id="customControlAutosizing"/>
                                                <label className="custom-control-label" htmlFor="customControlAutosizing">Remember me</label>
                                                <a href="#" className="btn btn-default forgot-pass">lost your password</a>
                                            </div>
                                        </div>
                                        <div className="form-button">
                                            <button className="btn btn-primary" type="submit">Login</button>
                                        </div>
                                        
                                    </form>
                                </div>
                                  {/* <div className="tab-pane fade" id="top-contact" role="tabpanel" aria-labelledby="contact-top-tab">
                                      <form className="form-horizontal auth-form">
                                          <div className="form-group">
                                              <input required="" name="login[username]" type="email" className="form-control" placeholder="Username" id="exampleInputEmail12"/>
                                          </div>
                                          <div className="form-group">
                                              <input required="" name="login[password]" type="password" className="form-control" placeholder="Password"/>
                                          </div>
                                          <div className="form-group">
                                              <input required="" name="login[password]" type="password" className="form-control" placeholder="Confirm Password"/>
                                          </div>
                                          <div className="form-terms">
                                              <div className="custom-control custom-checkbox mr-sm-2">
                                                  <input type="checkbox" className="custom-control-input" id="customControlAutosizing1"/>
                                                  <label className="custom-control-label" htmlFor="customControlAutosizing1"><span>I agree all statements in <a href="#"  className="pull-right">Terms &amp; Conditions</a></span></label>
                                              </div>
                                          </div>
                                          <div className="form-button">
                                              <button className="btn btn-primary" type="submit">Register</button>
                                          </div>
                                          <div className="form-footer">
                                              <span>Or Sign up with social platforms</span>
                                              <ul className="social">
                                                  <li><a className="icon-facebook" href="#"></a></li>
                                                  <li><a className="icon-twitter" href="#"></a></li>
                                                  <li><a className="icon-instagram" href="#"></a></li>
                                                  <li><a className="icon-pinterest" href="#"></a></li>
                                              </ul>
                                          </div>
                                      </form>
                                  </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <a href="index.html" className="btn btn-primary back-btn"><i data-feather="arrow-left"></i>back</a> */}
        </div>
    </div>
</div>
    </>
  )
}
