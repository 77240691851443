import React from "react";
import Allusers from "./Allusers";

export default function Dashboard() {
  return (
    <>
      <div className="page-body">
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-lg-6">
                <div className="page-header-left">
                  <h3>
                    Dashboard
                    <small>MarudharArts Admin panel</small>
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <ol className="breadcrumb pull-right">
                  <li className="breadcrumb-item">
                    <a href="index.html">
                      <i data-feather="home"></i>
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends-->

      <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-3 col-md-6 xl-50">
              <div className="card o-hidden widget-cards">
                <div className="bg-warning card-body">
                  <div className="media static-top-widget row">
                    <div className="icons-widgets col-4">
                      <div className="align-self-center text-center">
                        <i
                          data-feather="navigation"
                          className="font-warning"
                        ></i>
                      </div>
                    </div>
                    <div className="media-body col-8">
                      <span className="m-0">Users</span>
                      <h3 className="mb-0">
                        {" "}
                        <span className="counter"></span>
                        <small></small>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 xl-50">
              <div className="card o-hidden  widget-cards">
                <div className="bg-secondary card-body">
                  <div className="media static-top-widget row">
                    <div className="icons-widgets col-4">
                      <div className="align-self-center text-center">
                        <i data-feather="box" className="font-secondary"></i>
                      </div>
                    </div>
                    <div className="media-body col-8">
                      <span className="m-0">Products</span>
                      <h3 className="mb-0">
                        {" "}
                        <span className="counter"></span>
                        <small></small>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 xl-50">
              <div className="card o-hidden widget-cards">
                <div className="bg-primary card-body">
                  <div className="media static-top-widget row">
                    <div className="icons-widgets col-4">
                      <div className="align-self-center text-center">
                        <i
                          data-feather="message-square"
                          className="font-primary"
                        ></i>
                      </div>
                    </div>
                    <div className="media-body col-8">
                      <span className="m-0">Messages</span>
                      <h3 className="mb-0">
                        {" "}
                        <span className="counter"></span>
                        <small></small>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 xl-50">
              <div className="card o-hidden widget-cards">
                <div className="bg-danger card-body">
                  <div className="media static-top-widget row">
                    <div className="icons-widgets col-4">
                      <div className="align-self-center text-center">
                        <i data-feather="users" className="font-danger"></i>
                      </div>
                    </div>
                    <div className="media-body col-8">
                      <span className="m-0">New Vendors</span>
                      <h3 className="mb-0">
                        {" "}
                        <span className="counter"></span>
                        <small></small>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-xl-3 col-md-6 xl-50">
                  <div className="card order-graph sales-carousel">
                      <div className="card-header">
                          <h6>Total Sales</h6>
                          <div className="row">
                              <div className="col-6">
                                  <div className="small-chartjs">
                                      <div className="flot-chart-placeholder" id="simple-line-chart-sparkline-3"></div>
                                  </div>
                              </div>
                              <div className="col-6">
                                  <div className="value-graph">
                                      <h3>42% <span><i className="fa fa-angle-up font-primary"></i></span></h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="card-body">
                          <div className="media">
                              <div className="media-body">
                                  <span>Sales Last Month</span>
                                  <h2 className="mb-0">9054</h2>
                                  <p>0.25% <span><i className="fa fa-angle-up"></i></span></p>
                                  <h5 className="f-w-600">Gross sales of August</h5>
                                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                              </div>
                              <div className="bg-primary b-r-8">
                                  <div className="small-box">
                                      <i data-feather="briefcase"></i>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-xl-3 col-md-6 xl-50">
                  <div className="card order-graph sales-carousel">
                      <div className="card-header">
                          <h6>Total purchase</h6>
                          <div className="row">
                              <div className="col-6">
                                  <div className="small-chartjs">
                                      <div className="flot-chart-placeholder" id="simple-line-chart-sparkline"></div>
                                  </div>
                              </div>
                              <div className="col-6">
                                  <div className="value-graph">
                                      <h3>20% <span><i className="fa fa-angle-up font-secondary"></i></span></h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="card-body">
                          <div className="media">
                              <div className="media-body">
                                  <span>Monthly purchase</span>
                                  <h2 className="mb-0">2154</h2>
                                  <p>0.13% <span><i className="fa fa-angle-up"></i></span></p>
                                  <h5 className="f-w-600">Avg Gross purchase</h5>
                                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                              </div>
                              <div className="bg-secondary b-r-8">
                                  <div className="small-box">
                                      <i data-feather="credit-card"></i>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-xl-3 col-md-6 xl-50">
                  <div className="card order-graph sales-carousel">
                      <div className="card-header">
                          <h6>Total cash transaction</h6>
                          <div className="row">
                              <div className="col-6">
                                  <div className="small-chartjs">
                                      <div className="flot-chart-placeholder" id="simple-line-chart-sparkline-2"></div>
                                  </div>
                              </div>
                              <div className="col-6">
                                  <div className="value-graph">
                                      <h3>28% <span><i className="fa fa-angle-up font-warning"></i></span></h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="card-body">
                          <div className="media">
                              <div className="media-body">
                                  <span>Cash on hand</span>
                                  <h2 className="mb-0">4672</h2>
                                  <p>0.8% <span><i className="fa fa-angle-up"></i></span></p>
                                  <h5 className="f-w-600">Details about cash</h5>
                                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                              </div>
                              <div className="bg-warning b-r-8">
                                  <div className="small-box">
                                      <i data-feather="shopping-cart"></i>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-xl-3 col-md-6 xl-50">
                  <div className="card order-graph sales-carousel">
                      <div className="card-header">
                          <h6>Daily Deposits</h6>
                          <div className="row">
                              <div className="col-6">
                                  <div className="small-chartjs">
                                      <div className="flot-chart-placeholder" id="simple-line-chart-sparkline-1"></div>
                                  </div>
                              </div>
                              <div className="col-6">
                                  <div className="value-graph">
                                      <h3>75% <span><i className="fa fa-angle-up font-danger"></i></span></h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="card-body">
                          <div className="media">
                              <div className="media-body">
                                  <span>Security Deposits</span>
                                  <h2 className="mb-0">0782</h2>
                                  <p>0.25% <span><i className="fa fa-angle-up"></i></span></p>
                                  <h5 className="f-w-600">Gross sales of June</h5>
                                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                              </div>
                              <div className="bg-danger b-r-8">
                                  <div className="small-box">
                                      <i data-feather="calendar"></i>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> */}
          </div>
        </div>
        <div className="container-fluid">{/*  <Allusers/> */}</div>
        <br />
        {/* <!-- Container-fluid Ends--> */}
      </div>

      {/* <!-- footer start--> */}
    </>
  );
}
