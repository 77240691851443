import React,{useEffect,useContext} from 'react'
import Cateogryhometable from '../lookup/Cateogryhometable';
import { useNavigate } from 'react-router-dom';
import DashboardContext from '../../context/DashboardContext';

export default function ShowProducts() {
    const navigate = useNavigate();
    const a = useContext(DashboardContext);
    const {fetchHomecategory,homeCatdata} = a;
    useEffect(() => {
      console.log("user", localStorage.getItem("is_login"));
      fetchHomecategory();
      // eslint-disable-next-line
    }, []);
  return (
   <>
    <div className="page-body">
        <div className="container-fluid">
            <div className="page-header">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="page-header-left">
                            <h3>Categories
                                <small>MarudharArts Admin panel</small>
                            </h3>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <ol className="breadcrumb pull-right">
                            <li className="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li>
                            <li className="breadcrumb-item">Dashboard</li>
                            <li className="breadcrumb-item active">Products</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    {/* <h5>Manage Products</h5> */}
                    <div className="row">
                      <div className="col-sm-6">
                        <h5>Home Category</h5>
                      </div>
                      <div className="col-sm-6">
                        {/* <button className="btn btn-sm btn-primary start_left" onClick={()=> {navigate('/addUnlistedproduct')}}>Add</button> */}
                      </div>
                    </div>
                </div>
                
                <div className="card-body">
                  <Cateogryhometable data = {homeCatdata}/>
                </div>
            </div>
          </div>
        </div>
      </div>
      </div>
   </>
  )
}
