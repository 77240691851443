import React, { useState } from "react";

const AddFields = ({ onValueChange }) => {
  const [numberOfFields, setNumberOfFields] = useState(1);
  const [fieldData, setFieldData] = useState([]);

  const handleChange = (event) => {
    const value = event.target.value;
    const intValue = parseInt(value, 10);
    if (!isNaN(intValue) && intValue >= 1) {
      setNumberOfFields(intValue);
    } else {
      setNumberOfFields(1);
    }

    onValueChange(fieldData);
  };

  const handleFieldChange = (index, event) => {
    const { name, value } = event.target;
    const newFieldData = [...fieldData];
    newFieldData[index] = { ...newFieldData[index], [name]: value };
    setFieldData(newFieldData);

    onValueChange(newFieldData);
    
  };

  const handleSubmit = () => {
    /* e.priventDefault(); */
    // Do whatever you want with the fieldData array here (e.g., send it to the server, perform further operations)
    console.log("Field Data:", fieldData);
    
  };

  return (
    <div className="add_fields_custom">
      <div className="add-field">
        <label htmlFor="numberOfFields">Number of Fields:</label>
        <input
          type="number"
          id="numberOfFields"
          name="numberOfFields"
          className="form-control"
          value={numberOfFields}
          onChange={handleChange}
        />
      </div>
      <hr/>

      {/* Render input fields based on the number specified */}
      {Array.from({ length: numberOfFields }, (_, index) => (
        <div className="add_fields_custom" key={index}>
          <label htmlFor={`field-${index}`}>{`Field ${index + 1}:`}</label>
          <input
            type="text"
            className="form-control"
            id={`field-${index}`}
            name={`field-${index}`}
            placeholder={`Field ${index + 1}`}
            value={fieldData[index] ? fieldData[index][`field-${index}`] : ""}
            onChange={(e) => handleFieldChange(index, e)}
          />

          <label htmlFor={`description-${index}`}>Description:</label>
          <textarea
            id={`description-${index}`}
            name={`description-${index}`}
            className="form-control"
            placeholder={`Description for Field ${index + 1}`}
            value={
              fieldData[index] ? fieldData[index][`description-${index}`] : ""
            }
            onChange={(e) => handleFieldChange(index, e)}
          />
        </div>
      ))}

     {/*  <button type="button" onClick={handleSubmit}>Submit</button> */}
    </div>
  );
};

export default AddFields;
