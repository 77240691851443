import React, { useContext, useRef, useEffect } from "react";
import DashboardContext from "../../../context/DashboardContext";
import Alluserstable from '../../lookup/Alluserstable';
import CDNumberTable from "../../lookup/CDNumbertable";
import Userpermissions from "../Userpermissions";

export default function Allusers() {
  const a = useContext(DashboardContext);
  /* const modalRef = useRef(null); */
  const { getallUsers, usersData ,permissiondata} = a;
  /* const [first, setfirst] = useState(categoryGroup) */
  

  useEffect(() => {
    console.log("user", localStorage.getItem("is_login"));
    getallUsers();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="page-body">
        <div className="container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-lg-6">
                <div className="page-header-left">
                  <h3>
                    Users
                    <small>MarudharArts Admin panel</small>
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <ol className="breadcrumb pull-right">
                  <li className="breadcrumb-item">
                    <a href="index.html">
                      <i data-feather="home"></i>
                    </a>
                  </li>
                  <li className="breadcrumb-iteem">Dashboard</li>
                  <li className="breadcrumb-itm active">Products</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          {(Object.keys(permissiondata).length > 0) && <Userpermissions data ={permissiondata}/>}
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5>Users list</h5>
                    </div>
                    <div className="col-sm-6">
                      {/* <button
                        className="btn btn-sm btn-primary start_left"
                        data-toggle="modal"
                        data-target=".bd-example-modal-lg"
                      >
                        Add
                      </button> */}
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <Alluserstable data={usersData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* model */}
      {/*  <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Large modal</button> */}

      
    </>
  );
}
