import React, { useState ,useContext} from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, Button,IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DashboardContext from "../../context/DashboardContext";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here
import {
    Edit as EditIcon
  } from '@mui/icons-material';
/* import { data } from './makeData'; */

//defining columns outside of the component is fine, is stable
const columns = [
  {
    accessorKey: "_id",
    header: "ID",
    size: 40,
  },
  {
    accessorKey: "fullname",
    header: "Full Name",
    size: 120,
  },
  {
    accessorKey: "username",
    header: "Username",
    size: 120,
  },
  {
    accessorKey: "email",
    header: "Email",
    size: 300,
  },
  {
    accessorKey: "country",
    header: "Country",
    size: 300,
  }
];

const csvOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: columns.map((c) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);

const CDNumberTable = (props) => {

    const a = useContext(DashboardContext);
  /* const modalRef = useRef(null); */
  const { getUserPermission, permissiondata } = a;

  console.log("data", props.data);
  const [data, setdata] = useState(props.data);
  const handleExportRows = (rows) => {
    console.log("selected row ------>",rows);
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  const handleGetuserspermission = (row) =>{
    getUserPermission(row);
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={props.data}
      enableRowSelection
      enableRowActions
      positionActionsColumn = "last"
      positionToolbarAlertBanner="bottom"
      renderRowActions={({ row, table }) => (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
          
          <IconButton
            color="secondary"
            onClick={() => {
               //table.setEditingRow(row);
              /*  openModal(row); */

              handleGetuserspermission(row.original);

              console.log("get------------------>tabledata",row.original._id );
            }}
          >
            <EditIcon />
          </IconButton>
        </Box>
      )}
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
        >
          <Button
            color="primary"
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={handleExportData}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export All Data
          </Button>
          <Button
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            //export all rows, including from the next page, (still respects filtering and sorting)
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export All Rows
          </Button>
          <Button
            disabled={table.getRowModel().rows.length === 0}
            //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
            onClick={() => handleExportRows(table.getRowModel().rows)}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export Page Rows
          </Button>
          <Button
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            //only export selected rows
            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export Selected Rows
          </Button>
        </Box>
      )}
    />
  );
};

export default CDNumberTable;
