import React, { useContext, useRef, useEffect } from "react";

import DashboardContext from "../../context/DashboardContext";
import ProductConditionTable from "../lookup/ProductConditiontable";

export default function ProductCondition() {
  const a = useContext(DashboardContext);
  const modalRef = useRef(null);
  const { addCategoryGroup, fetch_condition ,conditions} = a;
  /* const [first, setfirst] = useState(categoryGroup) */
  const handleSubmitCategory = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let category = data.get("category");
    let order = data.get("order");
    let level = data.get("level");
    let status = data.get("status");

    console.log("status", status);
    addCategoryGroup(category, order, level, status);

    if (modalRef.current) {
      modalRef.current.style.display = "none";
      document.body.classList.remove("modal-open");
      const modalBackdrop = document.querySelector(".modal-backdrop");
      if (modalBackdrop) {
        modalBackdrop.parentNode.removeChild(modalBackdrop);
      }
    }
  };

  useEffect(() => {
    console.log("user", localStorage.getItem("is_login"));
    fetch_condition();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="page-body">
        <div className="container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-lg-6">
                <div className="page-header-left">
                  <h3>
                    Products
                    <small>MarudharArts Admin panel</small>
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <ol className="breadcrumb pull-right">
                  <li className="breadcrumb-item">
                    <a href="index.html">
                      <i data-feather="home"></i>
                    </a>
                  </li>
                  <li className="breadcrumb-iteem">Dashboard</li>
                  <li className="breadcrumb-itm active">Products</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5>Product Condition</h5>
                    </div>
                    {/* <div className="col-sm-6">
                      <button
                        className="btn btn-sm btn-primary start_left"
                        data-toggle="modal"
                        data-target=".bd-example-modal-lg"
                      >
                        Add
                      </button>
                    </div> */}
                  </div>
                </div>
                <div className="card-body">
                  <ProductConditionTable data={conditions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* model */}
      {/*  <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Large modal</button> */}

      <div
        ref={modalRef}
        className="modal fade bd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Product Condition
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={handleSubmitCategory}>
                <div className="modal-body">
                  <div className="form-group">
                    <label for="condition-name" className="col-form-label">
                      Condition <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="condition-name"
                      name="condition"
                      placeholder="Condition"
                    />
                  </div>

                  <div className="form-group">
                    <label for="recipient-name" className="col-form-label">
                      Status <span className="required">*</span>
                    </label>
                    <select className="form-control" name="status">
                      <option value="" selected>
                        Please Select
                      </option>
                      <option value="Active">Active</option>
                      <option value="Freezed">Freezed</option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
