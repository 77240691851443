import React, { useState } from "react";
import DashboardContext from "./DashboardContext";
import { apiPath } from "../constant";

const DashboardState = (props) => {
  const [islogin, setLogin] = useState(localStorage.getItem("is_login"));
  const [categoryGroup, setCategoryGroup] = useState({});
  const [categoryMasteradd, setcategoryMasteradd] = useState([]);
  const [mastercategory, setmastercategory] = useState([]);
  const [maincategory, setmaincategory] = useState([]);
  const [gstAll, setgstAll] = useState([]);
  const [subcategories, setsubcategories] = useState([]);
  const [subcategoriestwo, setsubcategoriestwo] = useState([]);
  const [subcategoriesthree, setsubcategoriesthree] = useState([]);
  const [productPageData, setProductPageData] = useState([]);
  const [homeCatdata, setHomecatdata] = useState([]);
  const [usersData, setAllusers] = useState([]);
  const [permissiondata, setpermissiondata] = useState([]);
  const [userData, setUserdata] = useState([]);
  const [consignor, setConsignor] = useState([]);
  const [cdnumbers, setCdno] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [expertusers, setExpert] = useState([]);
  const [referenceData, setReferences] = useState([]);
  const [keywordsData, setKeywords] = useState([]);
  const [products, setProducts] = useState([]);
  const [productResponse, setProductResponse] = useState({ Success: false });

  /* const [,addCdno] = useState([]); */

  // Login api
  const userLogin = async (username, password) => {
    setLogin(false);
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      username: username,
      password: password,
    });
    const endPoint = apiPath + "/auth/user_login";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log(data);
    if (data.Success) {
      console.log("login success");
      localStorage.setItem("user_data", data.resultData.token);
      localStorage.setItem("is_login", data.resultData.Success);
      setLogin(true);
    } else {
      setLogin(false);
    }
  };

  // AddCategoryGroup api
  const addCategoryGroup = async (category, order, level, status) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      category: category,
      order: order,
      level: level,
      status: status,
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/admin/add_categorygroup";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log(data);
    if (data.Success) {
      /* console.log("Category Group Data",data); */
      getCategoryGroup();
    } else {
    }
  };

  // AddCategoryMaster api
  const addCategoryMaster = async (category_master, parent_id, level) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      category: category_master,
      parent_id: parent_id,
      level: level,
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/admin/add_categorymaster";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("categorymaster", data);
    if (data.Success) {
      getcategoryMasterdropdown(parent_id);
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  //Add City addcity
  const addcity = async (city) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "2231231kjhkjhk",
      city: city,
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/admin/addcity";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("categorymaster", data);
    if (data.Success) {
      getaddproductData();
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };
  /* Get city */
  const getaddproductData = async () => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      /* "token":'ksbjdkasbd983742' */
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/admin/getaddproductData";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("-------------->product page data", data);
    if (data.Success) {
      /* console.log("Category Group Data",data); */

      setProductPageData(data.resultData);
    } else {
      /*  setLogin(false); */
    }
  };

  // AddCategoryGroup api
  const getCategoryGroup = async () => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "ksbjdkasbd983742",
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/admin/get_categorygroup";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log(data);
    if (data.Success) {
      /* console.log("Category Group Data",data); */

      setCategoryGroup(data.resultData);

      getaddproductData();
      fetchHomecategory();
      getCdno();
      fetch_condition();
      getallUsers();
      getexpertuser();
      fetch_keywords();
    } else {
      /*  setLogin(false); */
    }
  };

  // AddCategoryGroup api
  const getcategoryMasterdropdown = async (parent_id) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "ksbjdkasbd983742",
      parent_id: parent_id,
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/admin/getcategoryMasterdropdown";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log(data);
    if (data.Success) {
      /* console.log("Category Group Data",data); */

      setmastercategory(data.resultData);
      fetch_references(parent_id);
    } else {
      /*  setLogin(false); */
    }
  };

  /* getcategoryMaindropdown */

  const getcategoryMaindropdown = async (parent_id) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "ksbjdkasbd983742",
      parent_id: parent_id,
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/admin/getcategoryMaindropdown";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log(data);
    if (data.Success) {
      console.log("Category Main", data);

      setmaincategory(data.resultData);
    } else {
      /*  setLogin(false); */
    }
  };

  /* ===================================================================  ADD GST ================================================== */

  const addgst = async (category, rate, hsn, status) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "ksbjdkasbd983742",
      category: category,
      rate: rate,
      hsn: hsn,
      status: status,
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/admin/addgst";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();

    if (data.Success) {
      fetchAllgst();
    } else {
      /*  setLogin(false); */
    }
  };

  const fetchAllgst = async () => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "ksbjdkasbd983742",
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/admin/fetchAllgst";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("GST_repose", data);
    if (data.Success) {
      setgstAll(data);
    } else {
      /*  setLogin(false); */
    }
  };

  /* addCategoryMain */

  const addCategoryMain = async (
    mainparent_id,
    category_main,
    level,
    gst_id,
    meta_desc
  ) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      mainparent_id: mainparent_id,
      category_main: category_main,
      level: level,
      gst_id: gst_id,
      meta_desc: meta_desc,
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/admin/addCategoryMain";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("categorymain", data);
    if (data.Success) {
      getcategoryMaindropdown(mainparent_id);
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  /* ADD addCategorySub */

  const addCategorySub = async (parent_id, category, level) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      parent_id: parent_id,
      category: category,
      level: level,
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/admin/addCategorySub";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("category__sub_add", data);
    if (data.Success) {
      fetchSubcategories(parent_id, level);
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  /* Add addHomeCategorgy */
  const addHomeCategorgy = async (
    group,
    master,
    main,
    sub,
    sub2,
    sub3,
    home_cat,
    keyword
  ) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      group: group,
      master: master,
      main: main,
      sub: sub,
      sub2: sub2,
      sub3: sub3,
      home_cat: home_cat,
      keyword: keyword,
      status: "1",
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/admin/addHomeCategorgy";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("category__sub_add", data);
    if (data.Success) {
      fetchHomecategory();
    } else {
      /*  fetchHomecategory(); */
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };
  /* End */

  /* fetchHomecategory */
  const fetchHomecategory = async () => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      /*  "token":"23434sefsdf",
      "parent_id":parent_id,
      "level":level */
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/admin/fetchHomecategory";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("category__sub_add", data);
    if (data.Success) {
      setHomecatdata(data.resultData);
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  /* ============================ Fetch all getallUsers ================================================*/

  const getallUsers = async () => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "23434sefsdf",
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/users/fetch_users";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("category__sub_add", data);
    if (data.Success) {
      setAllusers(data.resultData);
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  /* =============================  End  =============================================== */
  /* end */

  /* Fetch subcategroies */

  const fetchSubcategories = async (parent_id, level) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "23434sefsdf",
      parent_id: parent_id,
      level: level,
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/admin/fetchSubcategories";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("category__sub_add", data);
    if (data.Success) {
      if (level == "sub1") {
        setsubcategories(data.resultData);
      } else if (level == "sub2") {
        setsubcategoriestwo(data.resultData);
      } else {
        setsubcategoriesthree(data.resultData);
      }
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  const getUserPermission = async (user) => {
    /* console.log("permmmisd-------------->",userid); */
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "873642834jh",
      userid: user._id,
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/users/fetch_permission";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("permission------------------->", data);
    if (data.Success) {
      setpermissiondata(data.resultData);
      setUserdata(user);
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  const set_permission = async (
    userID,
    isCheckedEmail,
    isCheckedPhone,
    isCheckedReference,
    isCheckedinfo,
    isCheckedtrust,
    isCheckedExpert,
    isCheckedCons,
    isChecked
  ) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "873642834jh",
      userid: userID,
      is_email: isCheckedEmail ? 1 : 0,
      is_mobile: isCheckedPhone ? 1 : 0,
      is_ref: isCheckedReference ? 1 : 0,
      is_info: isCheckedinfo ? 1 : 0,
      is_trust: isCheckedtrust ? 1 : 0,
      is_exprt: isCheckedExpert ? 1 : 0,
      is_cons: isCheckedCons ? 1 : 0,
      is_prxy: isChecked ? 1 : 0,
      login_status: 1,
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/users/set_permission";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("permission seetted------------------->", data);
    if (data.Success) {
      setpermissiondata([]);
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  /* create_expertUser */

  const create_expertUser = async (userID, isCheckedExpert) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "873642834jh",
      userID: userID,
      is_exprt: isCheckedExpert,
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/users/create_expertUser";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("permission seetted------------------->", data);
    if (data.Success) {
      getexpertuser();
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  /* End */

  const getConsignoruser = async () => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "873642834jh",
    });
    const endPoint = apiPath + "/users/getConsignoruser";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("permission seetted------------------->", data);
    if (data.Success) {
      setConsignor(data.resultData);
      getCdno();
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  const getexpertuser = async () => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "873642834jh",
    });
    const endPoint = apiPath + "/users/getexpertuser";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("permission expert------------------->", data);
    if (data.Success) {
      setExpert(data.resultData);
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  const addCdno = async (cd_no, consigner, entry, status) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "873642834jh",
      cd_no: cd_no,
      consigner: consigner,
      entry: entry,
      status: status,
    });

    console.log("bodyContent", bodyContent);
    const endPoint = apiPath + "/users/addCdno";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("permission seetted------------------->", data);
    if (data.Success) {
      setpermissiondata([]);
      getCdno();
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  const getCdno = async () => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "873642834jh",
    });
    const endPoint = apiPath + "/users/getCdno";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("permission cdno------------------->", data);
    if (data.Success) {
      setCdno(data.resultData);
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  const add_references = async (groupcatID, references, status) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "873642834jh",
      groupcatID: groupcatID,
      references: references,
      status: status,
    });
    const endPoint = apiPath + "/products/add_references";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("permission cdno------------------->", data);
    if (data.Success) {
      /* setCdno(data.resultData) */
      fetch_references(groupcatID);
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  const fetch_references = async (groupcatID) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "873642834jh",
      groupcatID: groupcatID,
    });
    const endPoint = apiPath + "/products/fetch_references";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("permission references------------------->", data);
    if (data.Success) {
      /* setCdno(data.resultData) */
      setReferences(data.resultData);
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  const addCondition = async (cond, status) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "873642834jh",
      cond: cond,
      status: status,
    });
    const endPoint = apiPath + "/products/add_condition";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("permission cdno------------------->", data);
    if (data.Success) {
      fetch_condition();
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  const fetch_condition = async () => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "873642834jh",
    });
    const endPoint = apiPath + "/products/fetch_condition";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("permission codition------------------->", data);
    if (data.Success) {
      setConditions(data.resultData);
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  const add_keyword = async (keyword) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "873642834jh",
      keyword: keyword,
    });
    const endPoint = apiPath + "/products/add_keywords";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("permission keywords------------------->", data);
    if (data.Success) {
      fetch_keywords();
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  const fetch_keywords = async () => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "873642834jh",
    });
    const endPoint = apiPath + "/products/fetch_keywords";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("permission KKKeyword------------------->", data);
    if (data.Success) {
      setKeywords(data.resultData);
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  const add_product = async (
    title,
    groupselectedKey,
    masterselectedKey,
    mainselectedKey,
    subselectedKey,
    suboneselectedKey,
    subtwoselectedKey,
    auction_city,
    auction_type,
    home_cat,
    cdsrn,
    cdsrno,
    qnty,
    size,
    whgt,
    cond,
    start_bid_price,
    estimate_price,
    reserv_price,
    e_shopprice,
    discount_price,
    selectedExprt,
    selected,
    shplcl,
    shpabrd,
    shpmtd,
    selectedKwd,
    headNdesc
  ) => {
    /*  setLogin(false); */
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };
    setProductResponse({ Success: false });
    let bodyContent = JSON.stringify({
      token: "873642834jh",
      title: title,
      groupcatID: groupselectedKey,
      mastercatID: masterselectedKey,
      maincatID: mainselectedKey,
      subcatID: subselectedKey,
      sub2catID: suboneselectedKey,
      sub3catID: subtwoselectedKey,
      auction_city: auction_city,
      auction_type: auction_type,
      home_cat: home_cat,
      cdsrn: cdsrn,
      cdsrno: cdsrno,
      qnty: qnty,
      size: size,
      whgt: whgt,
      cond: cond,
      start_bid_price: start_bid_price,
      estimate_price: estimate_price,
      reserv_price: reserv_price,
      e_shopprice: e_shopprice,
      discount_price: discount_price,
      selectedExprt: selectedExprt,
      selected: selected,
      shplcl: shplcl,
      shpabrd: shpabrd,
      shpmtd: shpmtd,
      selectedKwd: selectedKwd,
      headNdesc: headNdesc,
      desccription: "new product",
    });
    console.log("request body------------->", bodyContent);
    const endPoint = apiPath + "/products/add_product";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("permission keywords------------------->", data);
    if (data.Success) {
      setProductResponse({ Success: true });
    } else {
      /* setcategoryMasteradd([]); */
      // error alert
    }
  };

  /* End */

  /* Fetch product */
  const fetch_products = async () => {
    setProductResponse({ Success: false });
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      token: "873642834jh",
    });
    const endPoint = apiPath + "/products/fetch_products";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log("permission KKKeyword------------------->", data);
    if (data.Success) {
      setProducts(data.resultData);
    } else {
      /*  setProducts(); */
    }
  };

  /* End */

  const logout = async () => {
    localStorage.removeItem("is_login");
    setLogin(false);
  };

  return (
    <DashboardContext.Provider
      value={{
        islogin,
        userLogin,
        logout,
        addCategoryGroup,
        categoryGroup,
        getCategoryGroup,
        addCategoryMaster,
        categoryMasteradd,
        getcategoryMasterdropdown,
        mastercategory,
        addgst,
        fetchAllgst,
        gstAll,
        addCategoryMain,
        getcategoryMaindropdown,
        maincategory,
        addCategorySub,
        subcategories,
        subcategoriestwo,
        subcategoriesthree,
        fetchSubcategories,
        productPageData,
        getaddproductData,
        addcity,
        addHomeCategorgy,
        homeCatdata,
        usersData,
        getallUsers,
        permissiondata,
        getUserPermission,
        set_permission,
        userData,
        getConsignoruser,
        consignor,
        addCdno,
        cdnumbers,
        addCondition,
        conditions,
        expertusers,
        create_expertUser,
        add_references,
        referenceData,
        add_keyword,
        keywordsData,
        add_product,
        products,
        fetch_products,
        productResponse,
        fetchHomecategory,
        getexpertuser,
        fetch_condition,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};

export default DashboardState;
