import React, { useContext, useRef, useEffect ,useState} from "react";
//import Allusers from "../../Allusers";
import Modal from 'react-bootstrap/Modal';
import DashboardContext from "../../context/DashboardContext";
import ExpertTables from "../lookup/ExpertTables";

export default function UserConsignor() {
  const [lgShow, setLgShow] = useState(false);
  const a = useContext(DashboardContext);
  const modalRef = useRef(null);
  const {getexpertuser,addCdno,cdnumbers,expertusers} = a;
  /* const [first, setfirst] = useState(categoryGroup) */
  

  const handleAddcdnoategory = (e) =>{
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let cd_no = data.get("cd_no");
    let consigner = data.get("consigner");
    let entry = data.get("entry");
    let status = data.get("status");

    addCdno(cd_no,consigner,entry,status);

        modalRef.current.reset();
        setLgShow(false);
  }

  useEffect(() => {
    console.log("user", localStorage.getItem("is_login"));
    /* getCdno(); */
    getexpertuser();
    // eslint-disable-next-line
  }, []);

  console.log("expert users--------->",expertusers);
  console.log("cdnumbers--------------------------->",cdnumbers);
  return (
    <>
      <div className="page-body">
        <div className="container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-lg-6">
                <div className="page-header-left">
                  <h3>
                    Users
                    <small>MarudharArts Admin panel</small>
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <ol className="breadcrumb pull-right">
                  <li className="breadcrumb-item">
                    <a href="index.html">
                      <i data-feather="home"></i>
                    </a>
                  </li>
                  <li className="breadcrumb-iteem">Dashboard</li>
                  <li className="breadcrumb-itm active">Products</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5>Expert List</h5>
                    </div>
                    <div className="col-sm-6">
                     {/*  <button
                        className="btn btn-sm btn-primary start_left"
                        data-toggle="modal"
                        data-target=".bd-example-modal-lg"
                        onClick={() => setLgShow(true)}>
                        Add
                      </button> */}
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ExpertTables data={(!expertusers.users)?[]:expertusers.users} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* model */}
      {/*  <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Large modal</button> */}
      {/* <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add New CD Number
          </Modal.Title>
        </Modal.Header>
        <form ref={modalRef} onSubmit={handleAddcdnoategory}>
        <Modal.Body>
          <div className="modal-body">
            <div className="form-group">
              <label for="recipient-name" className="col-form-label">
                CD No <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                name="cd_no"
                placeholder="CD No"
              />
            </div>

            

            <div className="form-group">
              <label for="recipient-name" className="col-form-label">
                Consignor <span className="required">*</span>
              </label>
              <select className="form-control" name="consigner">
                <option value="" selected>
                  Please Select Consignor
                </option>
                {(Object.keys(consignor).length > 0) && consignor.map((con, index) => (
                  <option key={index} value={con._id}>
                    {con.username},{con.fullname}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label for="recipient-name" className="col-form-label">
                Entry Mode <span className="required">*</span>
              </label>
              <select className="form-control" name="entry">
                <option value="" selected>
                  Please Select
                </option>
                <option value="Admin Entry">Admin Entry</option>
                <option value="Self Entry">Self Entry</option>
              </select>
            </div>

            <div className="form-group">
              <label for="recipient-name" className="col-form-label">
                Status <span className="required">*</span>
              </label>
              <select className="form-control" name="status">
                <option value="" selected>
                  Please Select
                </option>
                <option value="1">Active</option>
                <option value="0">Freezed</option>
              </select>
            </div>
          </div>
              
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" className="btn btn-primary">Save</button>
        </Modal.Footer>
        </form>
      </Modal> */}
     {/*  <div
        ref={modalRef}
        className="modal fade bd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add New CD Number
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={handleSubmitCategory}>
                <div className="modal-body">
                  <div className="form-group">
                    <label for="recipient-name" className="col-form-label">
                      CD No <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      name="category"
                      placeholder="CD No"
                    />
                  </div>

                  <div className="form-group">
                    <label for="recipient-name" className="col-form-label">
                      Consignor Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      name="order"
                      placeholder="Consignor Name"
                    />
                  </div>

                  <div className="form-group">
                    <label for="recipient-name" className="col-form-label">
                      User Name <span className="required">*</span>
                    </label>
                    <select className="form-control" name="level">
                      <option value="" selected>
                        Please Select
                      </option>
                      <option value="Category Group">Category Group</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label for="recipient-name" className="col-form-label">
                      Entry Mode <span className="required">*</span>
                    </label>
                    <select className="form-control" name="level">
                      <option value="" selected>
                        Please Select
                      </option>
                      <option value="Category Entry">Admin Entry</option>
                      <option value="Self Entry">Self Entry</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label for="recipient-name" className="col-form-label">
                      Status <span className="required">*</span>
                    </label>
                    <select className="form-control" name="status">
                      <option value="" selected>
                        Please Select
                      </option>
                      <option value="Active">Active</option>
                      <option value="Freezed">Freezed</option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
